import {
  DistanceUnit,
  DistanceUnitShort,
  exactKgtoLbs,
  exactMetersToMiles,
  WeightUnit,
  divide,
  roundToTwoDecimal,
} from 'hevy-shared';
import { stores } from 'state/stores';

export const userExerciseWeightUnit = (exerciseTemplateId: string) => {
  const customWeightUnit = stores.exerciseTemplateUnits.exerciseUnit(exerciseTemplateId);

  if (customWeightUnit) {
    return customWeightUnit;
  }

  return stores.userPreferences.weightUnit;
};

/**
 * Should be used when displaying weight value to the user
 * for a specific exercise. Converts to their their custom
 * weight unit preferences for this particular exercise.
 */
export const userExerciseWeight = (exerciseTemplateId: string, weightKg: number) => {
  const weightUnitPreference = userExerciseWeightUnit(exerciseTemplateId);

  if (weightUnitPreference === 'lbs') {
    return roundToTwoDecimal(exactKgtoLbs(weightKg));
  }

  return roundToTwoDecimal(weightKg);
};

/**
 * Should be used when displaying weight value to the user.
 * Converts to the users preffered units and rounds to the
 * nearest two decimal. Keep in mind, this doesn't take into
 * account the weight settings on a specific exercise. For
 * that user `userExerciseWeight`.
 */
export const userWeight = (weightKg: number, unitPreference: WeightUnit) => {
  if (unitPreference === 'lbs') {
    return exactKgtoLbs(weightKg);
  }

  return weightKg;
};

export const userShortDistance = (distanceMeters: number) => {
  return distanceMeters;
};

/**
 * Returns a pace like 6000seconds/km or 6000seconds/mile
 * depending on the distance unit.
 */
export const userPace = (
  distanceMeters: number,
  durationSeconds: number,
  distanceUnit: DistanceUnit,
) => {
  if (distanceUnit === 'miles') {
    return divide(durationSeconds, exactMetersToMiles(distanceMeters));
  }

  return divide(durationSeconds, distanceMeters / 1000);
};

/**
 *
 * @param distanceMeters
 * @param unitPreference
 * @returns Distance in miles or kilometers based on the passed preference
 */
export const userDistance = (
  distanceMeters: number,
  unitPreference: DistanceUnit | DistanceUnitShort,
) => {
  if (unitPreference === 'miles' || unitPreference === 'mi') {
    return exactMetersToMiles(distanceMeters);
  }

  return roundToTwoDecimal(distanceMeters / 1000);
};
