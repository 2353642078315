import { Inter } from 'next/font/google';

export const inter = Inter({ subsets: ['latin'] });
const fontFamily = `${inter.style.fontFamily}`;

const fonts = {
  regular: {
    family: fontFamily,
    weight: '400',
  },
  medium: {
    family: fontFamily,
    weight: '500',
  },
};

export default fonts;
