import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import PlusImageAccent from './assets/icon-plus-accent.svg';
import VerticalDotsAccent from './assets/icon-vertical-dots-accent.svg';
import VerticalDots from './assets/icon-vertical-dots.svg';
import HorizontalDotsAccent from './assets/icon-horizontal-dots-accent.svg';
import CheckAccent from './assets/icon-check-accent.svg';
import CheckGreen from './assets/icon-check-green.svg';
import RestTimerAccent from './assets/icon-rest-timer-accent.svg';
import ChevronDownAccent from './assets/icon-chevron-down-accent.svg';
import Sets from './assets/sets.svg';
import Reps from './assets/reps.svg';
import Timer from './assets/timer.svg';
import Pr from './assets/pr.svg';
import RoutineAccent from './assets/routine-accent.svg';
import CreateFolderAccent from './assets/new-folder-accent.svg';
import Twitter from './assets/twitter.svg';
import Instagram from './assets/instagram.svg';
import Search from './assets/search.svg';
import XSmall from './assets/x-small.svg';
import Plus from './assets/plus.svg';
import Edit from './assets/edit.svg';
import XSmallGrey from './assets/x-small-grey.svg';
import PlusGrey from './assets/plus-grey.svg';
import EditGrey from './assets/edit-grey.svg';
import CopyGrey from './assets/copy-grey.svg';
import Reorder from './assets/reorder.svg';
import Replace from './assets/replace.svg';

const iconTypeImageMap = {
  plusAccent: PlusImageAccent,
  verticalDotsAccent: VerticalDotsAccent,
  verticalDots: VerticalDots,
  horizontalDotsAccent: HorizontalDotsAccent,
  checkAccent: CheckAccent,
  checkGreen: CheckGreen,
  restTimerAccent: RestTimerAccent,
  chevronDownAccent: ChevronDownAccent,
  xSmallGrey: XSmallGrey,
  copyGrey: CopyGrey,
  sets: Sets,
  reps: Reps,
  timer: Timer,
  routineAccent: RoutineAccent,
  createFolderAccent: CreateFolderAccent,
  twitter: Twitter,
  instagram: Instagram,
  search: Search,
  plusGrey: PlusGrey,
  editGrey: EditGrey,
  pr: Pr,
  reorder: Reorder,
  replace: Replace,
  xSmall: XSmall,
  plus: Plus,
  edit: Edit,
};

export type IconType = keyof typeof iconTypeImageMap;

const Container = styled.div`
  height: 30px;
  width: 30px;
`;

interface IconProps {
  type: IconType;
  style?: CSSProperties;
}

export const Icon = ({ type, style }: IconProps) => (
  <Container style={style}>{iconTypeImageMap[type]()}</Container>
);
