import dayjs from 'dayjs';
import {
  EquipmentFilter,
  ExerciseTemplate,
  filterExercises,
  isEquipmentFilter,
  isMuscleGroupFilter,
  MuscleGroupFilter,
  popularExerciseTemplateIds,
} from 'hevy-shared';
import { makeAutoObservable } from 'mobx';
import { stores } from 'state/stores';

interface SectionHeaderCell {
  type: 'section';
  title: string;
}

interface SpacerCell {
  type: 'spacer';
}

interface NoExercisesMatchSearchCell {
  type: 'no-exercises-match-search';
  searchText: string;
}

type KeyedExerciseTemplate = { key: string } & ExerciseTemplate;

export type ExerciseDataCell =
  | SpacerCell
  | KeyedExerciseTemplate
  | SectionHeaderCell
  | NoExercisesMatchSearchCell;

export class ExerciseLibraryViewModel {
  searchText: string = '';
  equipmentFilter: EquipmentFilter = 'all_equipment';
  musleGroupFilter: MuscleGroupFilter = 'all_muscles';

  constructor() {
    makeAutoObservable(this);
  }

  get allExercisesFiltered() {
    return filterExercises({
      exercises: stores.exerciseTemplates.exercises.map(e => ({
        ...e,
        localized_muscle_group_name: e.muscle_group,
      })),
      muscleGroupFilter: this.musleGroupFilter,
      equipmentType: this.equipmentFilter,
      searchText: this.searchText,
      language: 'en',
    }).map(e => ({ ...e, key: e.id }));
  }

  get popularExercisesAlphabeticalFiltered() {
    const popularExerciseTemplates = popularExerciseTemplateIds
      .map(id => stores.exerciseTemplates.getExercise(id))
      .filter((e): e is ExerciseTemplate => !!e);

    return filterExercises({
      exercises: popularExerciseTemplates.map(e => ({
        ...e,
        localized_muscle_group_name: e.muscle_group,
      })),
      muscleGroupFilter: this.musleGroupFilter,
      equipmentType: this.equipmentFilter,
      searchText: this.searchText,
      language: 'en',
    })
      .map(e => ({ ...e, key: `popular-${e.id}` }))
      .sort((a, b) => {
        return a.title.localeCompare(b.title);
      });
  }

  get data(): ExerciseDataCell[] {
    if (this.searchText.length) {
      if (this.allExercisesFiltered.length === 0) {
        return [
          {
            type: 'no-exercises-match-search',
            searchText: this.searchText,
          },
        ];
      }

      const allSection: ExerciseDataCell[] = [
        { type: 'section', title: 'Search Results' },
        ...this.allExercisesFiltered,
      ];

      return allSection;
    }

    const popularSection: ExerciseDataCell[] =
      dayjs(stores.account.last_workout_at).unix() === 0 &&
      this.popularExercisesAlphabeticalFiltered.length
        ? [
            { type: 'section', title: 'Popular Exercises' },
            ...this.popularExercisesAlphabeticalFiltered,
            { type: 'spacer' },
          ]
        : [];

    const allSection: ExerciseDataCell[] = [
      { type: 'section', title: 'All Exercises' },
      ...this.allExercisesFiltered,
    ];

    return [...popularSection, ...allSection];
  }

  onSearchTextUpdate = (value: string) => {
    this.searchText = value;
  };

  onEquipmentFilterUpdate = (value: string) => {
    if (isEquipmentFilter(value)) {
      this.equipmentFilter = value;
    }
  };

  onMuscleGroupFilterUpdate = (value: string) => {
    if (isMuscleGroupFilter(value)) {
      this.musleGroupFilter = value;
    }
  };
}
