import React from 'react';
import { ProfileImage } from 'components/ProfileImage';
import { ThemedHevyProLogo } from 'components/svg/ThemedHevyProLogo';
import Link from 'next/link';
import { stores } from 'state/stores';
import { PrimaryButton } from 'styleguide/Buttons';
import { TextSMMedium, TextMD, TextSMRegular } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { NavBarRoute } from '../NavBarRoute';
import styled, { ThemeProps } from 'styled-components';
import { colors } from 'styleguide/colors';
import { Theme } from 'styleguide/types';
import { MIN_WIDTH_FOR_SIDEBAR_NAVIGATION, themeState } from 'styleguide/theme';
import { SearchInput } from 'components/SearchInput';
import { CornerRadius, Spacing } from 'styleguide/spacing';
import { NavBarUserSearchViewModel } from './NavBarUserSearchViewModel';
import { observer } from 'mobx-react-lite';
import { ControlledMenu, useMenuState } from '@szhsin/react-menu';
import { ClipLoader } from 'react-spinners';
import { SearchUser } from 'hevy-shared';
import { modal } from 'components/Modals/ModalManager';
import { DividerLine } from 'styleguide/DividerLine';
import { autorun } from 'mobx';
import { LogoutButton } from './LogoutButton';
import { FlexRow } from 'styleguide/Row';

const CtaContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  margin-right: 8px;
  height: 30px;
  width: 100%;
  border: solid;
  border-radius: 7px;
  border-width: 1px;
  border-color: ${(props: ThemeProps<Theme>) =>
    props.theme.name === 'dark' ? '#636363' : colors.neutral200};
`;

export interface NavBarRoutesProps {
  pathname: string;
  showProCta: boolean;
  isSettingsEnabled: boolean;
  vm: NavBarUserSearchViewModel;
}

const SearchResultsContainer = styled.div`
  overflow-y: auto;
  min-width: min(332px, 90vw);
  max-height: min(300px, 90vh);
`;
const SearchResultsInnerContainer = styled.div`
  margin-left: ${Spacing.sm}px;
  margin-right: ${Spacing.sm}px;
  padding-top: ${Spacing.sm}px;
  padding-bottom: ${Spacing.sm}px;
`;

const NavBarTopContainer = styled(View)`
  padding: ${Spacing.md}px;
  @media (max-width: ${MIN_WIDTH_FOR_SIDEBAR_NAVIGATION}px) {
    padding: ${Spacing.sm}px;
  }
`;

const NavBarBottomContainer = styled(View)``;

const UserSearchResult = ({ user }: { user: SearchUser }) => {
  const [isHovering, setIsHovering] = React.useState(false);
  const hoverBackgroundColor = themeState.current.neutral100;
  return (
    <Link
      href={`/user/${user.username}`}
      style={{ textDecoration: 'none' }}
      onClick={() => {
        modal.isMobileNavBarModalOpen = false;
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          padding: Spacing.sm,
          backgroundColor: isHovering ? hoverBackgroundColor : undefined,
          borderRadius: CornerRadius.sm,
          cursor: 'pointer',
        }}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <ProfileImage diameter={40} source={user.profile_pic} />
        <View style={{ justifyContent: 'center', marginLeft: Spacing.sm }}>
          <TextSMMedium>{user.username}</TextSMMedium>
          {user.full_name && <TextSMRegular>{user.full_name}</TextSMRegular>}
        </View>
      </View>
    </Link>
  );
};

const SearchResultsDropdown = observer(({ vm }: { vm: NavBarUserSearchViewModel }) => {
  return (
    <SearchResultsContainer style={{ backgroundColor: themeState.current.background }}>
      <SearchResultsInnerContainer>
        {vm.isLoadingResults ? (
          <ClipLoader size={30} color={colors.primary500} loading={true} />
        ) : (
          <>
            {vm.results.length === 0 && (
              <>
                <TextMD style={{ flexWrap: 'wrap', flex: 1 }}>
                  Can't find "{vm.searchString}"
                </TextMD>
                <TextSMRegular>Maybe they aren't on Hevy yet</TextSMRegular>
              </>
            )}
            {vm.results.map(user => {
              return <UserSearchResult key={user.id} user={user} />;
            })}
          </>
        )}
      </SearchResultsInnerContainer>
    </SearchResultsContainer>
  );
});

export const NavBarMainContent = observer(
  ({ pathname, showProCta, isSettingsEnabled, vm }: NavBarRoutesProps) => {
    const menuRef = React.useRef(null);
    const [menuState, toggleMenu] = useMenuState({ transition: true });
    autorun(() => {
      vm.showMenu ? toggleMenu(true) : toggleMenu(false);
    });
    return (
      <View style={{ justifyContent: 'space-between', flex: 1 }}>
        <NavBarTopContainer>
          <SearchInput
            style={{ marginBottom: Spacing.md, width: '100%' }}
            value={vm.searchString}
            type={'primary'}
            placeholder={'Search users'}
            onChange={vm.onSearchStringChanged}
          />
          <View
            ref={(ref: any) => {
              menuRef.current = ref;
            }}
          ></View>
          <ControlledMenu
            menuStyle={{ padding: 0, margin: 0, overflow: 'hidden', borderRadius: CornerRadius.md }}
            anchorRef={menuRef}
            captureFocus={false}
            direction="bottom"
            {...menuState}
          >
            <SearchResultsDropdown vm={vm} />
          </ControlledMenu>

          <NavBarRoute title="Feed" iconType="home-navbar" isSelected={pathname === '/'} path="/" />
          <NavBarRoute
            title="Routines"
            iconType="routine-navbar"
            isSelected={
              pathname.startsWith('/routine') ||
              pathname.startsWith('/create-routine') ||
              pathname.startsWith('/edit-routine')
            }
            path="/routines"
          />

          <NavBarRoute
            title="Exercises"
            iconType="exercises-navbar"
            isSelected={pathname.startsWith('/exercise')}
            path="/exercise"
          />

          {stores.account.is_coached && (
            <NavBarRoute
              title="Coach"
              iconType="coach-navbar"
              isSelected={pathname === '/coach'}
              path="/coach"
            />
          )}

          <NavBarRoute
            title="Profile"
            iconType="profile-navbar"
            isSelected={pathname === '/profile'}
            path="/profile"
          />

          {isSettingsEnabled && (
            <NavBarRoute
              title="Settings"
              iconType="settings"
              isSelected={pathname.startsWith('/settings')}
              path="/settings"
            />
          )}

          {showProCta && (
            <Link
              href="/plans"
              style={{ textDecoration: 'none', paddingTop: Spacing.md, paddingRight: Spacing.md }}
            >
              <CtaContainer>
                <ThemedHevyProLogo width={75}></ThemedHevyProLogo>
                <PrimaryButton style={{ width: 70, height: 30 }} title="Unlock"></PrimaryButton>
              </CtaContainer>
            </Link>
          )}
        </NavBarTopContainer>
        <NavBarBottomContainer>
          <DividerLine />
          <FlexRow style={{ justifyContent: 'space-between', padding: Spacing.md }}>
            <FlexRow>
              <ProfileImage
                style={{ marginRight: 12 }}
                source={stores.account.profile_pic}
                diameter={34}
              />
              <TextSMMedium>{stores.account.full_name || stores.account.username}</TextSMMedium>
            </FlexRow>

            <LogoutButton />
          </FlexRow>
        </NavBarBottomContainer>
      </View>
    );
  },
);
