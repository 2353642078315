import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import API, { SocialLoginResponse } from 'utils/API';

export class Auth {
  get isAuthenticated(): boolean {
    return !!Cookies.get('auth-token');
  }

  login = async (usernameOrEmail: string, password: string) => {
    const result = await API.login(usernameOrEmail, password);
    const authToken = result.data.auth_token;
    this.setAuthToken(authToken);
  };

  signup = async (
    email: string,
    password: string,
    recaptchaToken: string,
    gympassUserId?: string,
  ) => {
    const result = await API.signup(email, password, recaptchaToken, gympassUserId);
    const authToken = result.data.auth_token;
    this.setAuthToken(authToken);
  };

  loginWithGoogle = async (
    email: string,
    userId: string,
    idToken: string,
    gympassUserId?: string,
  ): Promise<SocialLoginResponse> => {
    const result = await API.signInWithGoogle(email, userId, idToken, gympassUserId);
    this.setAuthToken(result.data?.auth_token);
    return result.data;
  };

  loginWithApple = async ({
    identityToken,
    email,
    gympassUserId,
  }: {
    identityToken: string;
    email?: string;
    gympassUserId?: string;
  }): Promise<SocialLoginResponse> => {
    const result = await API.signInWithApple({ identityToken, email, gympassUserId });
    this.setAuthToken(result.data?.auth_token);
    return result.data;
  };

  logout = () => {
    API.setAuthToken(undefined);
    Cookies.remove('auth-token');
  };

  private setAuthToken = (newToken: string) => {
    if (!newToken) return;

    API.setAuthToken(newToken);
    Cookies.set('auth-token', newToken, { expires: dayjs().add(10, 'year').toDate() });
  };
}
