import { debounce } from 'lodash';
import API from './API';
import { logout } from './logout';
import { AxiosError } from 'axios';
import { sendEvent } from './events';

export const initAPInterceptors = () => {
  API.attachErrorInterceptor(invalidTokenLogoutInterceptor);
};

const invalidTokenLogoutInterceptor = (error: AxiosError) => {
  if (
    error.response &&
    error.response?.status === 401 &&
    error.response?.data === 'InvalidAuthToken'
  ) {
    debouncedLogout();
  }
};

const debouncedLogout = debounce(
  () => {
    sendEvent('invalid-auth-token-logout');
    console.warn('⚠️ Found invalid auth-token');
    logout();
  },
  5000,
  {
    leading: true,
    trailing: false,
  },
);
