import { ClientInvite, ClientsCoachData } from 'hevy-shared';
import { makeAutoObservable } from 'mobx';
import API from 'utils/API';

export class Coach {
  private _invite?: ClientInvite = undefined;
  private _coach_data?: ClientsCoachData = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get isInvitedByCoach() {
    return !!this._invite;
  }

  get invitingCoachName() {
    return this._invite?.coach_name ?? this._invite?.coach_username;
  }

  get invitingCoachProfilePic() {
    return this._invite?.coach_profile_pic ?? undefined;
  }

  acceptInvite = async () => {
    if (!this._invite) {
      return;
    }

    await API.acceptCoachInvite(this._invite.coach_username);
    this._invite = undefined;
  };

  declineInvite = async () => {
    if (!this._invite) {
      return;
    }

    await API.declineCoachInvite(this._invite.coach_username);
    this._invite = undefined;
  };

  get coachName() {
    return this._coach_data?.full_name ?? this._coach_data?.username;
  }

  get coachProfilePic() {
    return this._coach_data?.profile_pic ?? undefined;
  }

  private fetchInvite = async () => {
    try {
      const result = await API.getCoachInvites();
      const coachInvites = result.data;
      this._invite = coachInvites[0];
    } catch (e) {}
  };

  private fetchCoach = async () => {
    try {
      const result = await API.getCoach();
      this._coach_data = result.data;
    } catch (e) {
      if ((e as any)?.response?.status === 404) {
        this._coach_data = undefined;
      }
    }
  };

  fetch = async () => {
    await Promise.all([this.fetchInvite(), this.fetchCoach()]);
  };
}
