import React, { useState } from 'react';
import styled from 'styled-components';
import Cropper from 'react-easy-crop';
import { PrimaryButton, SecondaryButton } from 'styleguide/Buttons';
import { getCroppedImgJpeg } from 'utils/imageUtils';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const CropContainer = styled.div`
  position: relative;
  height: 80%;
  width: 100%;
`;

const SaveButtonContainer = styled.div`
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 20%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

interface CropImageProps {
  imageUrl?: string;
  onImageCropped: (url: string) => void;
  onCancel: () => void;
}

export const CropImage = ({ imageUrl, onImageCropped, onCancel }: CropImageProps) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [pixelCrop, setPixelCrop] = useState({ x: 0, y: 0, width: 0, height: 0 });

  return (
    <Container>
      <CropContainer>
        <Cropper
          image={imageUrl}
          crop={crop}
          zoom={zoom}
          aspect={1}
          showGrid={false}
          onCropChange={setCrop}
          onCropComplete={(_, pixelCrop) => {
            setPixelCrop(pixelCrop);
          }}
          onZoomChange={setZoom}
        />
      </CropContainer>
      <SaveButtonContainer>
        <SecondaryButton
          style={{ width: 200, marginRight: 16 }}
          title="Cancel"
          onClick={onCancel}
        />
        <PrimaryButton
          style={{ width: 200 }}
          title="Save"
          onClick={async () => {
            if (!imageUrl) return;

            const croppedImageUrl = await getCroppedImgJpeg(imageUrl, pixelCrop);
            onImageCropped(croppedImageUrl);
          }}
        />
      </SaveButtonContainer>
    </Container>
  );
};
