import React, { useEffect, useState } from 'react';
import { CSSProperties } from 'react';
import styled from 'styled-components';
import PlaceholderImage from './assets/profile-placeholder.svg';

interface StyledProfileImageProps {
  diameter: number;
}

const StyledProfileImage = styled.img`
  height: ${(props: StyledProfileImageProps) => `${props.diameter}px`};
  width: ${(props: StyledProfileImageProps) => `${props.diameter}px`};
  border-radius: 50%;
  cursor: pointer;
`;

const StyledPlaceholder = styled(PlaceholderImage)`
  height: ${(props: StyledProfileImageProps) => `${props.diameter}px`};
  width: ${(props: StyledProfileImageProps) => `${props.diameter}px`};
  cursor: pointer;
`;

interface SizedProfileImageProps {
  source?: string;
  style?: CSSProperties;
}

type ProfileImageProp = StyledProfileImageProps & SizedProfileImageProps;

export const ProfileImage = ({ source, diameter, style }: ProfileImageProp) => {
  const [isSourceBroken, setSourceBroken] = useState(false);

  useEffect(() => {
    setSourceBroken(isSourceBroken);
  }, [isSourceBroken]);

  return source && !isSourceBroken ? (
    <StyledProfileImage
      style={style}
      diameter={diameter}
      src={source}
      onError={() => {
        setSourceBroken(true);
      }}
    />
  ) : (
    <StyledPlaceholder style={style} diameter={diameter} />
  );
};
