import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import React, { CSSProperties, useState } from 'react';
import { Menu, MenuItem, MenuProps } from '@szhsin/react-menu';
import { themeState } from 'styleguide/theme';
import { observer } from 'mobx-react-lite';
import { Icon, IconType } from 'styleguide/Icon';
import { Spacing } from 'styleguide/spacing';
import { TextMDRegular } from 'styleguide/Texts';
import { ThemedIcon, ThemedIconType } from 'styleguide/ThemedIcon';

export const HevyMenu = observer((props: MenuProps) => (
  <Menu
    position="auto"
    overflow="auto"
    portal={true}
    viewScroll="close"
    menuStyle={{ backgroundColor: themeState.current.secondary }}
    {...props}
    transition={true}
  >
    {props.children}
  </Menu>
));

interface HevyMenuItemProps {
  title: string;
  iconType?: IconType;
  themedIconType?: ThemedIconType;
  onClick: () => void;
  tint?: string;
  iconStyle?: CSSProperties;
  textStyle?: CSSProperties;
}

export const HevyMenuItem = (props: HevyMenuItemProps) => {
  const hoverBackgroundColor = themeState.current.neutral200;
  const [isHovering, setIsHovering] = useState(false);
  return (
    <MenuItem
      onClick={props.onClick}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      style={{ background: isHovering ? hoverBackgroundColor : undefined }}
    >
      {props.themedIconType && (
        <ThemedIcon
          style={{ marginLeft: -Spacing.sm, ...props.iconStyle }}
          type={props.themedIconType}
        ></ThemedIcon>
      )}
      {props.iconType && (
        <Icon style={{ marginLeft: -Spacing.sm, ...props.iconStyle }} type={props.iconType}></Icon>
      )}
      <TextMDRegular type="secondary" style={{ marginLeft: Spacing.sm, ...props.textStyle }}>
        {props.title}
      </TextMDRegular>
    </MenuItem>
  );
};

export const HevyOptionMenu = (props: { children: React.ReactNode; style?: CSSProperties }) => {
  return (
    <HevyMenu
      menuButton={
        <div
          style={{
            display: 'flex',
            cursor: 'pointer',
            justifyContent: 'center',
            ...props.style,
          }}
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <div style={{ cursor: 'pointer', padding: Spacing.sm }}>
            <ThemedIcon type="options" />
          </div>
        </div>
      }
      onItemClick={e => {
        e.syntheticEvent.stopPropagation();
        e.syntheticEvent.preventDefault();
      }}
    >
      {props.children}
    </HevyMenu>
  );
};

export const menuItemStyle = () => ({
  hover: {
    backgroundColor: themeState.current.neutral200,
  },
});
