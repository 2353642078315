import { ProfileImage } from 'components/ProfileImage';
import { CommentInput } from 'components/WorkoutCell/components/CommentInput';
import { Comments } from 'components/WorkoutCell/components/Comments';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { stores } from 'state/stores';
import styled from 'styled-components';
import { DividerLine } from 'styleguide/DividerLine';
import { Modal } from 'styleguide/Modal';
import { TextMD, TextSMMedium, TextSMRegular } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { CommentModalViewModel } from './CommentModalViewModel';
import { UserHeader } from '../../../screens/WorkoutDetail/components/UserHeader';
import { CornerRadius, Spacing } from 'styleguide/spacing';
import { themeState } from 'styleguide/theme';
import { WorkoutActions } from 'components/WorkoutActions';
import { Stats } from 'components/WorkoutCell/components/Stats';
import { Link } from 'components/Link';

const Container = styled(View)`
  border-radius: ${CornerRadius.md}px;
  width: 600px;
  @media (max-width: 600px) {
    width: 90vw;
  }
`;

const TopContainer = styled(View)`
  gap: ${Spacing.md}px;
  padding: ${Spacing.lg}px;
  padding-bottom: ${Spacing.md}px;
`;

const MiddleContainer = styled(View)`
  padding: ${Spacing.md}px ${Spacing.lg}px;
  flex: 1;
  overflow-y: auto;
`;

const BottomContainer = styled(View)`
  padding: ${Spacing.lg}px;
  padding-top: ${Spacing.md}px;
`;

export interface CommentModalProps {
  vm: CommentModalViewModel;
  isOpen: boolean;
  onClose: () => void;
}

export const CommentModal = observer(({ vm, isOpen, onClose }: CommentModalProps) => {
  const onBottomRefChange = (node: HTMLDivElement) => {
    vm.setCommentsContainerRef(node);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} contentStyle={{ padding: 0, maxHeight: '90vh' }}>
      <Container style={{ colorScheme: themeState.current.name }}>
        <TopContainer>
          <Link href={`/user/${vm.data.username}`}>
            <View onClick={onClose}>
              <UserHeader
                username={vm.data.username}
                date={vm.data.date}
                profileImage={vm.data.profileImage}
              />
            </View>
          </Link>

          <Link href={`/workout/${vm.data.shortId || vm.data.workoutId}`}>
            <View style={{ gap: Spacing.md }} onClick={onClose}>
              <View>
                <TextSMMedium>{vm.data.title}</TextSMMedium>
                {vm.data.description && (
                  <TextSMRegular type="secondary">{vm.data.description}</TextSMRegular>
                )}
              </View>
              <Stats stats={vm.data.stats} />
            </View>
          </Link>
        </TopContainer>

        <DividerLine />

        <MiddleContainer>
          {vm.comments.length > 0 ? (
            <View>
              <Comments
                comments={vm.comments}
                onCommentDelete={vm.onCommentDelete}
                onUserClick={() => {
                  onClose();
                }}
                showAllComments={true}
              />
              <div ref={onBottomRefChange} />
            </View>
          ) : (
            <View
              style={{
                flex: 1,
                padding: Spacing.lg * 2,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ProfileImage
                style={{ marginBottom: Spacing.md }}
                diameter={50}
                source={stores.account.profile_pic}
              />
              <TextMD type="secondary">Be the first to comment</TextMD>
            </View>
          )}
        </MiddleContainer>

        <WorkoutActions
          isLiked={vm.data.isLiked}
          onLike={vm.data.onLike}
          onShare={vm.data.onShare}
        />

        <BottomContainer>
          <CommentInput onSend={vm.onCommentSend} profilePic={vm.profileImage} />
        </BottomContainer>
      </Container>
    </Modal>
  );
});
