import { env } from 'env';
import { PaddlePrice } from 'hevy-shared';
import { makeAutoObservable } from 'mobx';
import API from 'utils/API';

export class PaddlePrices {
  prices: PaddlePrice[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  get monthlyPrice() {
    return this.prices.find(p => p.billing_period === 'month');
  }

  get yearlyPrice() {
    return this.prices.find(p => p.billing_period === 'year');
  }

  get lifetimePrice() {
    return this.prices.find(p => p.billing_period === 'lifetime');
  }

  fetch = async () => {
    try {
      const result = await API.getPaddlePrices();
      this.prices = result.data;
    } catch (e) {
      // swallow error if in development
      if (env.env === 'development') return;

      throw e;
    }
  };
}
