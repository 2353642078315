import { ProfileImage } from 'components/ProfileImage';
import { observer } from 'mobx-react-lite';
import React, { CSSProperties } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { PrimaryButton, SecondaryButton } from 'styleguide/Buttons';
import { colors } from 'styleguide/colors';
import { Modal } from 'styleguide/Modal';
import { H1, TextMD } from 'styleguide/Texts';
import { themeState } from 'styleguide/theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 544px;
  border-radius: 10px;
  @media (max-width: 1024px) {
    width: 300px;
  }
`;

const UpperContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 16px 20px;
  margin: 0 -20px 16px -20px;
  border-bottom: 1px solid ${colors.neutral200};
`;

const MiddleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const LowerContainer = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
  padding: 16px 20px 0 20px;
  margin: 16px -20px 0 -20px;
  border-top: 1px solid ${colors.neutral200};
`;

export interface CoachInviteModalProps {
  coachName?: string;
  coachProfilePic?: string;
  onAcceptInvite: () => void;
  onDeclineInvite: () => void;
  isOpen: boolean;
  onClose: () => void;
}

export const CoachInviteModal = observer(
  ({
    coachName,
    coachProfilePic,
    onAcceptInvite,
    onDeclineInvite,
    isOpen,
    onClose,
  }: CoachInviteModalProps) => {
    return (
      <ThemeProvider theme={themeState.current}>
        <Modal isOpen={isOpen} onClose={() => null}>
          <Container>
            <UpperContainer>
              <H1>Coaching Invitation</H1>
            </UpperContainer>
            <MiddleContainer>
              <ProfileImage
                source={coachProfilePic}
                diameter={80}
                style={{ marginTop: 8, marginBottom: 24 }}
              />
              <TextMD>
                You have been invited to become a client of {coachName}. By doing so, {coachName}{' '}
                will be able to access your workouts, your data, and your measurements.
              </TextMD>
            </MiddleContainer>
            <LowerContainer>
              <SecondaryButton
                style={{ ...styles.button, marginRight: 16 }}
                title={'Decline Invitation'}
                onClick={() => {
                  onDeclineInvite();
                  onClose();
                }}
              />
              <PrimaryButton
                style={styles.button}
                title={'Accept Invitation'}
                onClick={() => {
                  onAcceptInvite();
                  onClose();
                }}
              />
            </LowerContainer>
          </Container>
        </Modal>
      </ThemeProvider>
    );
  },
);

const styles: { [key: string]: CSSProperties } = {
  button: {
    display: 'flex',
    width: 200,
  },
};
