import { observer } from 'mobx-react-lite';
import React from 'react';
import { themeState } from 'styleguide/theme';

interface ThemedHevyProLogoProps {
  width?: number;
  height?: number;
}

export const ThemedHevyProLogo = observer((props: ThemedHevyProLogoProps) => {
  return <SvgHevyPro fill={themeState.current.primary} {...props} />;
});

interface SvgLogoProp {
  fill: string;
  width?: number;
  height?: number;
}

function SvgHevyPro(props: SvgLogoProp) {
  return (
    <svg
      width="164px"
      height="40px"
      viewBox="0 0 164 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="nonzero">
        <path
          d="M6.912 32V21.474h7.915V32h7.017V5.48h-7.017v9.874H6.912V5.48H-.106V32h7.018zm30.552.49c1.142 0 2.387-.198 3.733-.592a12.364 12.364 0 003.693-1.775l-2.285-4.161c-.735.408-1.435.714-2.101.918a7 7 0 01-2.06.306c-1.143 0-2.136-.238-2.98-.714-.842-.476-1.427-1.326-1.754-2.55h11.628c.055-.245.116-.64.184-1.184.068-.544.102-1.128.102-1.754 0-1.36-.184-2.638-.55-3.835-.368-1.197-.919-2.244-1.653-3.142-.735-.897-1.66-1.605-2.775-2.121-1.115-.517-2.42-.776-3.916-.776a9.316 9.316 0 00-3.632.735 9.492 9.492 0 00-3.12 2.121c-.912.925-1.646 2.047-2.204 3.366-.558 1.32-.836 2.809-.836 4.468 0 1.686.272 3.196.816 4.529.544 1.333 1.285 2.455 2.223 3.366a9.44 9.44 0 003.325 2.08c1.279.477 2.666.715 4.162.715zm2.203-13.138H33.63c.217-1.034.619-1.782 1.203-2.244.585-.462 1.272-.694 2.06-.694 1.062 0 1.79.293 2.184.878.394.584.591 1.271.591 2.06zM62.794 32l6.446-20.4h-6.69l-2.204 8.894c-.49 2.04-.98 4.162-1.469 6.365h-.163c-.49-2.203-.98-4.325-1.469-6.365L55.042 11.6h-7.018L54.716 32h8.078zm13.172 7.67c1.25 0 2.346-.156 3.284-.469a7.132 7.132 0 002.51-1.448c.734-.653 1.38-1.483 1.937-2.489.558-1.006 1.109-2.203 1.653-3.59L92.204 11.6h-6.691l-2.04 7.834c-.245.979-.47 1.944-.673 2.896-.204.952-.415 1.918-.633 2.897h-.163c-.245-1.033-.476-2.02-.694-2.958a38.354 38.354 0 00-.775-2.835L78.087 11.6H71.07l7.752 19.666-.245.897c-.218.626-.565 1.122-1.04 1.49-.477.367-1.19.55-2.143.55-.217 0-.442-.027-.673-.081a13.92 13.92 0 01-.632-.164l-1.224 5.304c.462.136.918.238 1.367.306.448.068 1.026.102 1.734.102z"
          fill={props.fill}
        />
        <path
          d="M108.379 22v-8.048h2.562c1.105 0 2.17-.121 3.195-.362s1.93-.623 2.713-1.146a5.696 5.696 0 001.87-2.065c.461-.854.692-1.904.692-3.15 0-1.225-.216-2.255-.648-3.09a5.404 5.404 0 00-1.778-2.019c-.754-.512-1.638-.884-2.653-1.115a14.71 14.71 0 00-3.27-.347h-7.838V22h5.155zm2.17-12.148h-2.17V4.818h2.26c.463 0 .91.03 1.342.09.432.06.819.181 1.16.362a2.1 2.1 0 01.814.754c.201.321.302.733.302 1.236 0 .502-.1.919-.302 1.25a2.26 2.26 0 01-.829.8 3.722 3.722 0 01-1.19.421 7.74 7.74 0 01-1.387.121zM127.255 22v-8.47h1.748l4.612 8.47h6.029l-5.577-9.134c1.387-.422 2.472-1.125 3.256-2.11.783-.984 1.175-2.2 1.175-3.647 0-1.226-.23-2.25-.693-3.075a5.597 5.597 0 00-1.854-1.99 7.797 7.797 0 00-2.622-1.07 14.135 14.135 0 00-3-.316h-8.139V22h5.065zm2.411-12.208h-2.441V4.818h2.743c.381 0 .778.03 1.19.09.412.06.784.176 1.116.347.331.17.603.412.814.723.21.312.316.719.316 1.221 0 .543-.116.985-.347 1.327a2.301 2.301 0 01-.889.783 4.13 4.13 0 01-1.206.377c-.442.07-.874.106-1.296.106zm22.795 12.78c1.668 0 3.215-.27 4.642-.813 1.427-.543 2.658-1.311 3.693-2.306 1.035-.995 1.843-2.19 2.426-3.587.583-1.397.874-2.94.874-4.627 0-1.709-.29-3.25-.874-4.628-.583-1.376-1.391-2.547-2.426-3.511-1.035-.965-2.266-1.708-3.693-2.23-1.427-.523-2.974-.785-4.642-.785-1.668 0-3.21.262-4.627.784-1.417.523-2.643 1.266-3.678 2.23-1.035.965-1.844 2.136-2.426 3.512-.583 1.377-.875 2.92-.875 4.628 0 1.688.292 3.23.875 4.627.582 1.396 1.391 2.592 2.426 3.587 1.035.995 2.261 1.763 3.678 2.306 1.417.542 2.959.814 4.627.814zm0-4.702c-.904 0-1.728-.166-2.472-.497A5.712 5.712 0 01148.075 16a6.318 6.318 0 01-1.251-2.095c-.302-.814-.452-1.703-.452-2.667 0-.925.145-1.789.437-2.593a5.867 5.867 0 011.25-2.065 5.984 5.984 0 011.93-1.356c.744-.332 1.568-.498 2.472-.498.904 0 1.733.166 2.487.498a5.775 5.775 0 011.929 1.356c.533.573.95 1.261 1.25 2.065.302.804.453 1.668.453 2.593 0 .964-.15 1.853-.452 2.667a6.186 6.186 0 01-1.266 2.095 5.875 5.875 0 01-1.93 1.372c-.743.331-1.567.497-2.471.497z"
          fill="#F7D000"
        />
      </g>
    </svg>
  );
}
