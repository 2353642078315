import React from 'react';
import NextLink from 'next/link';
import { CSSProperties } from 'styled-components';

interface Props {
  href: string;
  children: React.ReactNode;
  onClick?: () => void;
  style?: CSSProperties;
}

export const Link = ({ href, children, onClick, style }: Props) => {
  return (
    <NextLink
      href={href}
      passHref={true}
      style={{ ...style, textDecoration: 'none' }}
      onClick={onClick}
    >
      {children}
    </NextLink>
  );
};
