import React, { CSSProperties } from 'react';
import styled, { css, ThemeProps } from 'styled-components';
import { colors } from 'styleguide/colors';
import { Icon } from 'styleguide/Icon';
import { Input } from 'styleguide/Inputs';
import { Theme } from 'styleguide/types';

const Container = styled.div<{ type: 'primary' | 'secondary' }>`
  display: flex;
  flex-direction: column;
  ${(props: { type: 'primary' | 'secondary' } & ThemeProps<Theme>) =>
    props.type === 'primary'
      ? css`
          background-color: ${props.theme.name === 'light'
            ? colors.neutral100
            : colors.darkmodeNeutral100};
        `
      : css`
          background-color: ${props.theme.name === 'light'
            ? colors.white
            : colors.darkmodeNeutral100};
          border: solid;
          border-width: 1px;
          border-color: ${props.theme.name === 'light'
            ? colors.neutral200
            : colors.darkmodeNeutral200};
        `}
`;

const ClearInputContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const InputContainer = styled.div`
  display: flex;
  height: 40px;
  flex: 1;
  flex-direction: row;
  align-items: center;
  @-moz-document url-prefix() {
    /* Without this the clear text button is misplaced on firefox */
    margin-right: -20px;
  }
`;

interface SearchInputProps {
  value: string;
  type: 'primary' | 'secondary';
  placeholder: string;
  onChange: (value: string) => void;
  spellCheck?: boolean;
  style?: CSSProperties;
}

export const SearchInput = ({
  value,
  type,
  placeholder,
  onChange,
  spellCheck,
  style,
}: SearchInputProps) => {
  return (
    <Container
      type={type}
      style={{
        borderRadius: 7,
        padding: '2px',
        flexDirection: 'row',
        ...style,
      }}
    >
      <InputContainer>
        <Icon
          type={'search'}
          style={{ marginLeft: '8px', marginRight: '8px', height: 20, width: 21 }}
        />
        <Input
          style={{ backgroundColor: colors.transparent, width: '90%' }}
          placeholder={placeholder}
          value={value}
          spellCheck={spellCheck}
          onChange={e => onChange(e.currentTarget.value)}
        />
      </InputContainer>

      {!!value.length && (
        <ClearInputContainer onClick={() => onChange('')}>
          <SvgClearSearch />
        </ClearInputContainer>
      )}
    </Container>
  );
};

function SvgClearSearch() {
  return (
    <svg width="30px" height="30px" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 5.5a9.5 9.5 0 110 19 9.5 9.5 0 010-19zm3.451 6.142a.75.75 0 00-.976.072L15 14.19l-2.475-2.475a.75.75 0 00-1.06 1.061l2.474 2.475-2.475 2.475-.072.084a.75.75 0 00.072.977l.085.072a.75.75 0 00.976-.072L15 16.31l2.475 2.475a.75.75 0 001.06-1.061l-2.474-2.475 2.475-2.475.072-.084a.75.75 0 00-.072-.977z"
        fill="#D8D8D8"
        fillRule="evenodd"
      />
    </svg>
  );
}
