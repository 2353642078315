import React from 'react';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import RSelect, { Props } from 'react-select';
import { themeState } from 'styleguide/theme';
import { Theme } from 'styleguide/types';
import { colors } from 'styleguide/colors';

export const isUpdate = (x: unknown): x is { label: string; value: string } => {
  return !!(x as any)?.label && !!(x as any)?.value;
};

const controlStyle = (theme: Theme): CSSProperties => {
  return { backgroundColor: theme.neutral50, borderColor: theme.neutral150 };
};

export const Multiselect = observer((props: Props) => {
  const theme = themeState.current;

  return (
    <RSelect
      isMulti={true}
      isSearchable={false}
      theme={theme => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary50: colors.primary500,
        },
      })}
      styles={{
        control: styles => ({ ...styles, ...controlStyle(theme) }),
        indicatorSeparator: styles => ({ ...styles, ...{ backgroundColor: 'transparent' } }),
        dropdownIndicator: styles => ({ ...styles, ...{ color: theme.accent } }),
        option: (styles, state) => ({
          ...styles,
          ...{ color: theme.primary },
          color: theme.primary,
          backgroundColor: state.isSelected
            ? colors.primary500
            : state.isFocused
            ? colors.primary300
            : theme.background,
        }),
        menu: styles => ({ ...styles, ...{ backgroundColor: theme.background } }),
      }}
      {...props}
    />
  );
});

export const Select = observer((props: Props) => {
  const theme = themeState.current;

  return (
    <RSelect
      isMulti={false}
      isSearchable={false}
      theme={theme => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary50: colors.primary500,
        },
      })}
      styles={{
        control: styles => ({ ...styles, ...controlStyle(theme) }),
        singleValue: styles => ({ ...styles, ...{ color: theme.primary } }),
        indicatorSeparator: styles => ({ ...styles, ...{ backgroundColor: 'transparent' } }),
        dropdownIndicator: styles => ({ ...styles, ...{ color: theme.accent } }),
        option: (styles, state) => ({
          ...styles,
          ...{ color: theme.primary },
          color: theme.primary,
          backgroundColor: state.isSelected
            ? colors.primary500
            : state.isFocused
            ? colors.primary300
            : theme.background,
        }),
        menu: styles => ({ ...styles, ...{ backgroundColor: theme.background } }),
      }}
      {...props}
    />
  );
});
