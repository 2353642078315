import React, { CSSProperties, useState } from 'react';
import { ExerciseImage } from 'components/ExerciseImage';
import styled from 'styled-components';
import { Icon } from 'styleguide/Icon';
import { TextSMMedium, TextSMRegular } from 'styleguide/Texts';
import { MuscleGroup } from 'hevy-shared';
import { CustomExerciseChicklet } from './CustomExerciseChicklet';
import { View } from 'styleguide/View';
import { colors } from 'styleguide/colors';
import { themeState } from 'styleguide/theme';
import { muscleGroupFilterToHuman } from 'utils/exerciseUtils';
import { Spacing } from 'styleguide/spacing';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${Spacing.sm}px;
  cursor: pointer;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

interface AddExerciseCellProps {
  image?: string;
  title: string;
  muscleGroup: MuscleGroup;
  isCustom: boolean;
  hidePlusIcons?: boolean;
  onClick: () => void;
  highlight: boolean;
  style?: CSSProperties;
}

export const AddExerciseCell = ({
  image,
  title,
  muscleGroup,
  isCustom,
  hidePlusIcons,
  highlight,
  onClick,
  style,
}: AddExerciseCellProps) => {
  const [wasRecentlyAdded, setWasRecentlyAdded] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const backgroundColor = () => {
    if (highlight) {
      return themeState.current.name === 'light' ? colors.neutral100 : colors.darkmodeNeutral150;
    }

    if (isHovering) {
      return themeState.current.name === 'light' ? colors.neutral50 : colors.darkmodeNeutral100;
    }
  };

  return (
    <Container
      style={{
        ...{
          backgroundColor: backgroundColor(),
        },
        ...style,
      }}
      onClick={() => {
        onClick();
        setWasRecentlyAdded(true);
        setTimeout(() => {
          setWasRecentlyAdded(false);
        }, 1000);
      }}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <ContentContainer>
        {!hidePlusIcons &&
          (wasRecentlyAdded ? (
            <Icon type="checkAccent" style={{ marginLeft: Spacing.sm, marginRight: Spacing.sm }} />
          ) : (
            <Icon type="plusAccent" style={{ marginLeft: Spacing.sm, marginRight: Spacing.sm }} />
          ))}
        {!!hidePlusIcons && <div style={{ marginLeft: Spacing.sm, marginRight: Spacing.sm }} />}

        <ExerciseImage source={image} diameter={48} style={{ marginRight: Spacing.sm }} />
        <TextContainer>
          <TextSMMedium>{title}</TextSMMedium>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <TextSMRegular style={{ marginRight: Spacing.sm }}>
              {muscleGroupFilterToHuman(muscleGroup)}
            </TextSMRegular>
            {isCustom && <CustomExerciseChicklet />}
          </View>
        </TextContainer>
      </ContentContainer>
    </Container>
  );
};
