import styled, { ThemeProps } from 'styled-components';
import { Theme } from './types';

export const DividerLine = styled.div`
  background-color: ${(props: ThemeProps<Theme>) => props.theme.border};
  width: 100%;
  height: 1px;
`;

export const DividerLineVertical = styled.div`
  background-color: ${(props: ThemeProps<Theme>) => props.theme.border};
  height: 100%;
  width: 1px;
`;
