import React from 'react';
import { Link } from 'components/Link';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import styled from 'styled-components';
import { colors } from 'styleguide/colors';
import { TextSMMedium } from 'styleguide/Texts';
import { ThemeState, themeState } from 'styleguide/theme';
import { ThemedIcon, ThemedIconType } from 'styleguide/ThemedIcon';
import { CornerRadius, Spacing } from 'styleguide/spacing';
import { modal } from 'components/Modals/ModalManager';

interface NavBarRouteProps {
  title: string;
  iconType: ThemedIconType;
  isSelected: boolean;
  path: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 100%;
  border-radius: ${CornerRadius.sm}px;
  padding-top: ${Spacing.sm}px;
  padding-bottom: ${Spacing.sm}px;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: ${Spacing.sm}px;
  gap: ${Spacing.sm}px;
`;

export const NavBarRoute = observer(({ title, isSelected, path, iconType }: NavBarRouteProps) => {
  const [isHovering, setIsHovering] = useState(false);

  const tint = ((themeState: ThemeState) => {
    if (isSelected) return themeState.current.accent;

    return themeState.current.name === 'light' ? colors.neutral500 : colors.neutral400;
  })(themeState);

  const backgroundColor = ((themeState: ThemeState) => {
    if (isSelected) {
      return themeState.current.name === 'light' ? colors.primary50 : colors.darkModePrimary100;
    } else if (isHovering) {
      return themeState.current.name === 'light' ? colors.neutral50 : colors.darkmodeNeutral100;
    }
    return undefined;
  })(themeState);

  return (
    <Link href={path} style={{ marginBottom: Spacing.sm }}>
      <Container
        style={{ backgroundColor: backgroundColor }}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        onClick={() => {
          modal.isMobileNavBarModalOpen = false;
        }}
      >
        <Content>
          <ThemedIcon style={{ width: 24, height: 24 }} type={iconType} dark={tint} light={tint} />
          <TextSMMedium style={{ color: tint }}>{title}</TextSMMedium>
        </Content>
      </Container>
    </Link>
  );
});
