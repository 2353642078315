export interface Env {
  env: string;
  apiKey: string;
  apiUrl: string;
}

export const env: Env = {
  env: process.env.NEXT_PUBLIC_ENV || '',
  apiKey: process.env.NEXT_PUBLIC_API_KEY || '',
  apiUrl: process.env.NEXT_PUBLIC_API_URL || '',
};

if (process.env.NEXT_PUBLIC_ENV !== 'production') {
  (global as any).env = env;
}
