export const Spacing = {
  xs: 4,
  sm: 8,
  md: 16,
  lg: 24,
};

export const CornerRadius = {
  xs: 4,
  sm: 6,
  md: 8,
  xl: 16,
};
