import { useEffect, useState } from 'react';

export default function useScreenDimensions() {
  function getCurrentDimension(): { width: number; height: number } {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  if (typeof window !== 'undefined') {
    const [screenSize, setScreenSize] = useState(getCurrentDimension());

    useEffect(() => {
      const updateDimension = () => {
        setScreenSize(getCurrentDimension());
      };
      window.addEventListener('resize', updateDimension);

      return () => {
        window.removeEventListener('resize', updateDimension);
      };
    }, [screenSize]);
    return screenSize;
  }

  return { width: 0, height: 0 };
}
