import { AmplitudeClient } from 'amplitude-js';
import { env } from 'env';
import { log } from './log';

export const sendEvent = (event: string, params?: {}) => {
  log(`👣 amplitudeEvent: ${event} params: `, params);

  if (env.env === 'production') {
    const webPrefix = '[WEB]-';
    amplitudeEvent(`${webPrefix}${event}`, params);
  }
};

/**
 * Amplitude
 */

let amplitude: { getInstance: () => AmplitudeClient } | undefined;

export const initAmplitude = () => {
  if (typeof window) {
    log('initAmplitude called');
    amplitude = require('amplitude-js');
    amplitude?.getInstance().init('57b5a5fe4eae0e7b251e6b5daccf0480');
  }
};

export const setAmplitudeUserId = (userId: string) => {
  if (typeof window) {
    amplitude?.getInstance().setUserId(userId);
  }
};

const amplitudeEvent = (name: string, params?: {}) => {
  if (typeof window) {
    amplitude?.getInstance().logEvent(name, params);
  }
};
