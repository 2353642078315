import dayjs from 'dayjs';
import { configure } from 'mobx';
import relativeTime from 'dayjs/plugin/relativeTime';
import { env } from 'env';
import { stores } from 'state/stores';
import { initAmplitude, sendEvent } from './events';
import { log } from './log';
import { fetchUserContentAfterLaunch } from './fetchUserContent';
import { setupThemeCookie } from './themeCookie';
import { logout } from 'utils/logout';
import { setSentryUserData } from './sentry';
import { initPaddle } from './paddle';
import { initAPInterceptors } from './APInterceptors';

dayjs.extend(relativeTime);

configure({
  enforceActions: 'never',
});

export const bootstrap = async () => {
  log('🥾 bootstrapping');
  log(`🔗 api: `, env.apiUrl);

  initPaddle();
  initAmplitude();
  setSentryUserData();
  setupThemeCookie();

  // hevy.com/workout/1234 = workout
  const path = window.location.pathname.split('/')[1];
  sendEvent('appLaunch', { authenticated: stores.auth.isAuthenticated, path });

  stores.paddlePrices.fetch();

  initAPInterceptors();

  if (!stores.auth.isAuthenticated) {
    stores.lifecycle.setBootstrapComplete();
    return;
  }

  stores.account.hydrate();
  stores.userPreferences.hydrate();
  stores.routines.hydrate();
  stores.routineFolders.hydrate();
  stores.exerciseTemplates.hydrate();
  stores.exerciseTemplateUnits.hydrate();
  stores.followingStatus.hydrate();
  stores.followCounts.hydrate();
  stores.betaFeatures.hydrate();
  stores.serverStorage.hydrate();

  await stores.subscription.fetch().catch(error => {
    if (error.response?.status === 401) {
      logout();
    }
  });

  stores.lifecycle.setBootstrapComplete();

  await fetchUserContentAfterLaunch();
};
