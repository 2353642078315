import React from 'react';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import ReactModal from 'react-modal';
import { themeState } from './theme';
import { CornerRadius } from './spacing';
import { colors } from './colors';

export const zIndex = {
  aboveSurface: 1, //for things like tooltips, empty states, etc
  modal: 1000,
  modalOverlay: 1001, //for modals that can opened on top of other modals
  mobileNavBar: 1002,
};

const defaultContentStyle: CSSProperties = {
  top: '50%',
  left: '50%',
  right: 'auto',
  bottom: 'auto',
  marginRight: '-50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: CornerRadius.md,
  maxHeight: '80vh',
  maxWidth: '90%',
  display: 'flex',
  backgroundColor: colors.white,
};

const defaultOverlayStyle: CSSProperties = {
  backgroundColor: 'rgba(16, 18, 19, 0.3)',
  zIndex: zIndex.modal,
};

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  shouldCloseOnOverlayClick?: boolean;
  shouldCloseOnEsc?: boolean;
  overlayStyle?: CSSProperties;
  contentStyle?: CSSProperties;
}

export const Modal = observer(
  ({
    isOpen,
    onClose,
    children,
    shouldCloseOnOverlayClick,
    shouldCloseOnEsc,
    overlayStyle,
    contentStyle,
  }: ModalProps) => {
    return (
      <ReactModal
        isOpen={isOpen}
        onRequestClose={onClose}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        shouldCloseOnEsc={shouldCloseOnEsc}
        style={{
          content: {
            ...defaultContentStyle,
            colorScheme: themeState.current.name,
            backgroundColor: themeState.current.secondary,
            ...contentStyle,
          },
          overlay: { ...defaultOverlayStyle, ...overlayStyle },
        }}
      >
        {children}
      </ReactModal>
    );
  },
);
