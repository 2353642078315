import { calculateWorkoutStats } from 'components/WorkoutCell/utils';
import dayjs from 'dayjs';
import { WorkoutComment } from 'hevy-shared';
import { makeAutoObservable } from 'mobx';
import toast from 'react-hot-toast';
import { stores } from 'state/stores';
import API from 'utils/API';
import { copyToClipboard } from 'utils/clipboard';
import { sendEvent } from 'utils/events';
import { getRelativeDate } from 'utils/misc';

interface CommentModalViewModelProps {
  workoutId: string;
}

export class CommentModalViewModel {
  workoutId: string;
  private _commentsContainerRef?: HTMLDivElement;

  constructor({ workoutId }: CommentModalViewModelProps) {
    this.workoutId = workoutId;

    if (!stores.workouts.workouts[this.workoutId]) {
      throw new Error('Cannot fetch workout');
    }

    makeAutoObservable(this);
  }

  get data() {
    const workout = stores.workouts.workouts[this.workoutId];

    return {
      workoutId: this.workoutId,
      shortId: workout.short_id ?? undefined,
      title: workout.name,
      description: workout.description,
      username: workout.username,
      date: getRelativeDate(dayjs.unix(workout.end_time)),
      profileImage: workout.profile_image,
      stats: calculateWorkoutStats(workout, stores.userPreferences),
      onShare: () => {
        sendEvent('workoutCell_sharePress');
        copyToClipboard(`https://${location.hostname}/workout/${workout.id}`);
        toast.success('Copied link to clipboard');
      },
      isLiked: workout.is_liked_by_user,
      onLike: this.onLikeWorkout,
    };
  }

  get profileImage() {
    return stores.account.profile_pic;
  }

  get comments(): WorkoutComment[] {
    return stores.workouts.workouts[this.workoutId]?.comments || [];
  }

  setCommentsContainerRef = (ref: HTMLDivElement) => {
    this._commentsContainerRef = ref;
  };

  onLikeWorkout = async () => {
    await stores.workouts.likeWorkout(this.workoutId);
    stores.workouts.fetchWorkout(this.workoutId);
  };

  onCommentSend = async (comment: string) => {
    if (comment.length === 0) return;

    await API.postWorkoutComment(this.workoutId, comment);
    stores.workouts.fetchWorkout(this.workoutId);

    setTimeout(() => {
      this._commentsContainerRef?.scrollIntoView({ behavior: 'smooth' });
    }, 300);
  };

  onCommentDelete = async (commentId: number) => {
    await API.deleteWorkoutComment(commentId);
    stores.workouts.fetchWorkout(this.workoutId);
  };
}
