import styled, { ThemeProps } from 'styled-components';
import { Theme } from './types';
import { View } from './View';
import { CornerRadius } from './spacing';

export const Card = styled(View)`
  background-color: ${(props: ThemeProps<Theme>) => props.theme.secondary};
  border-radius: ${CornerRadius.md}px;
  border: 1px solid ${(props: ThemeProps<Theme>) => props.theme.border};
`;
