import dayjs from 'dayjs';
import Cookies from 'js-cookie';

export const setupThemeCookie = () => {
  if (!process.browser) return;

  const theme = Cookies.get('theme');

  if (!theme || ['light', 'dark'].includes(theme)) return;

  // Cookie not set. Set it based on the system preferences
  const systemTheme =
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'dark'
      : 'light';

  Cookies.set('theme', systemTheme, { expires: dayjs().add(10, 'year').toDate() });
};
