import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { PrimaryButton, SecondaryButton } from 'styleguide/Buttons';
import { colors } from 'styleguide/colors';
import { Modal, zIndex } from 'styleguide/Modal';
import { Spacing } from 'styleguide/spacing';
import { TextLG, TextSMRegular } from 'styleguide/Texts';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  border-radius: 10px;
  padding: ${Spacing.lg}px;
`;

const UpperContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const LowerContainer = styled.div`
  display: flex;
  margin-top: 20px;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: ${Spacing.sm}px;
`;

export interface AlertModalProps {
  title: string;
  body?: string;
  confirmButtonStyle?: 'primary' | 'destructive';
  confirmButtonTitle: string;
  cancelButtonTitle?: string;
  isOpen: boolean;
  onClose?: (confirm: boolean) => void;
}

export const AlertModal = ({
  title,
  body,
  confirmButtonStyle,
  confirmButtonTitle,
  cancelButtonTitle,
  isOpen,
  onClose,
}: AlertModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose?.(false);
      }}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      overlayStyle={{ zIndex: zIndex.modalOverlay }}
      contentStyle={{ padding: Spacing.md }}
    >
      <Container>
        <UpperContainer>
          <TextLG style={{ marginBottom: Spacing.xs }}>{title}</TextLG>
          {body && <TextSMRegular style={{ color: colors.neutral500 }}>{body}</TextSMRegular>}
        </UpperContainer>
        <LowerContainer>
          {cancelButtonTitle && (
            <SecondaryButton
              style={styles.button}
              title={cancelButtonTitle}
              onClick={() => {
                onClose?.(false);
              }}
            />
          )}
          <PrimaryButton
            style={confirmButtonStyle === 'destructive' ? styles.destructiveButton : styles.button}
            title={confirmButtonTitle}
            onClick={() => {
              onClose?.(true);
            }}
          />
        </LowerContainer>
      </Container>
    </Modal>
  );
};

const styles: { [key: string]: CSSProperties } = {
  button: {
    display: 'flex',
    flex: 1,
    minWidth: 175,
  },
  destructiveButton: {
    display: 'flex',
    flex: 1,
    backgroundColor: colors.error500,
    minWidth: 175,
  },
};
