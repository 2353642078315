import { makeAutoObservable } from 'mobx';

export class LifeCycle {
  isBootstrapped: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setBootstrapComplete = () => {
    this.isBootstrapped = true;
  };
}
