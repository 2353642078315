import API from './API';
import { dataURLtoFile } from './imageUtils';

export const uploadImage = async (fileName: string, imageUrl: string): Promise<{ url: string }> => {
  const file = dataURLtoFile(imageUrl, fileName);

  const result = await API.getPresignedUrl(file.name);
  const presignedUrl = result.data.presigned_url;
  await API.uploadFileWithPresignedUrl({ presignedUrl: result.data.presigned_url, file });

  const url = presignedUrl.split('?')[0];

  return { url };
};
