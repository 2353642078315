// db.ts
import Dexie, { type EntityTable } from 'dexie';
import { Routine } from 'hevy-shared';

const db = new Dexie('HevyDatabase') as Dexie & {
  routines: EntityTable<Routine, 'id'>;
};

// Schema declaration:
db.version(1).stores({
  routines: '++id',
});

export { db };
