import styled, { ThemeProps } from 'styled-components';
import { colors } from './colors';
import fonts from './fonts';
import { Theme } from './types';

type TextType = 'primary' | 'secondary' | 'tertiary';
type RegularTextType = 'primary' | 'secondary';

interface TextProps {
  type?: TextType;
}

interface RegularTextProps {
  type?: RegularTextType;
}

export const H1 = styled.h1<TextProps>`
  font-family: ${fonts.medium.family};
  font-size: 20px;
  color: ${(props: TextProps & ThemeProps<Theme>) => getTextColor(props.theme, props.type)};
  margin: 0px;
`;

export const H1Big = styled.h1<TextProps>`
  font-family: ${fonts.medium.family};
  font-size: 30px;
  color: ${(props: TextProps & ThemeProps<Theme>) => getTextColor(props.theme, props.type)};
  margin: 0px;
`;

export const DisplaySM = styled.h1<TextProps>`
  font-family: ${fonts.medium.family};
  font-weight: ${fonts.medium.weight};
  font-size: 30px;
  line-height: 38px;
  color: ${(props: TextProps & ThemeProps<Theme>) => getTextColor(props.theme, props.type)};
  margin: 0px;
`;

export const DisplayXS = styled.h1<TextProps>`
  font-family: ${fonts.medium.family};
  font-weight: ${fonts.medium.weight};
  font-size: 24px;
  line-height: 32px;
  color: ${(props: TextProps & ThemeProps<Theme>) => getTextColor(props.theme, props.type)};
  margin: 0px;
`;

export const TextLG = styled.p<TextProps>`
  font-family: ${fonts.medium.family};
  font-weight: ${fonts.medium.weight};
  font-size: 18px;
  color: ${(props: TextProps & ThemeProps<Theme>) => getTextColor(props.theme, props.type)};
  line-height: 28px;
  margin: 0px;
`;

export const TextLGRegular = styled.p<RegularTextProps>`
  font-family: ${fonts.regular.family};
  font-weight: ${fonts.regular.weight};
  font-size: 18px;
  color: ${(props: RegularTextProps & ThemeProps<Theme>) =>
    getRegularTextColor(props.theme, props.type)};
  line-height: 28px;
  margin: 0px;
`;

export const TextMD = styled.p<TextProps>`
  font-family: ${fonts.medium.family};
  font-weight: ${fonts.medium.weight};
  font-size: 16px;
  line-height: 24px;
  color: ${(props: TextProps & ThemeProps<Theme>) => getTextColor(props.theme, props.type)};
  margin: 0px;
`;

export const TextMDRegular = styled.p<RegularTextProps>`
  font-family: ${fonts.regular.family};
  font-weight: ${fonts.regular.weight};
  font-size: 16px;
  line-height: 24px;
  color: ${(props: RegularTextProps & ThemeProps<Theme>) =>
    getRegularTextColor(props.theme, props.type)};
  margin: 0px;
`;

export const TextSMMedium = styled.p<TextProps>`
  font-family: ${fonts.medium.family};
  font-weight: ${fonts.medium.weight};
  font-size: 14px;
  line-height: 20px;
  color: ${(props: TextProps & ThemeProps<Theme>) => getTextColor(props.theme, props.type)};
  margin: 0px;
  overflow-wrap: break-word;
  word-wrap: break-word;
`;

export const TextSMRegular = styled.p<RegularTextProps>`
  font-family: ${fonts.regular.family};
  font-weight: ${fonts.regular.weight};
  font-size: 14px;
  line-height: 20px;
  color: ${(props: RegularTextProps & ThemeProps<Theme>) =>
    getRegularTextColor(props.theme, props.type)};
  margin: 0px;
`;

export const TextXSMedium = styled.p<TextProps>`
  font-family: ${fonts.medium.family};
  font-weight: ${fonts.medium.weight};
  font-size: 12px;
  line-height: 18px;
  color: ${(props: TextProps & ThemeProps<Theme>) => getTextColor(props.theme, props.type)};
  margin: 0px;
`;

export const TextXSRegular = styled.p<RegularTextProps>`
  font-family: ${fonts.regular.family};
  font-weight: ${fonts.regular.weight};
  font-size: 12px;
  line-height: 18px;
  color: ${(props: RegularTextProps & ThemeProps<Theme>) =>
    getRegularTextColor(props.theme, props.type)};
  margin: 0px;
`;

export const Table = styled.p<TextProps>`
  font-family: ${fonts.medium.family};
  font-weight: ${fonts.medium.weight};
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${(props: TextProps & ThemeProps<Theme>) => getTextColor(props.theme, props.type)};
  margin: 0px;
`;

const getTextColor = (theme: Theme, type?: TextType) => {
  switch (type) {
    case 'secondary':
      return colors.neutral500;

    case 'tertiary':
      return theme.name === 'light' ? colors.neutral500 : colors.white;

    default:
      return theme.primary;
  }
};

const getRegularTextColor = (theme: Theme, type?: RegularTextType) => {
  switch (type) {
    case 'secondary':
      return theme.name === 'light' ? colors.neutral900 : colors.white;
    default:
      return colors.darkmodeNeutral500;
  }
};
