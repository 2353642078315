import Cookies from 'js-cookie';
import { makeAutoObservable } from 'mobx';
import { DocumentContext } from 'next/document';
import cookie from 'cookie';
import { colors } from './colors';
import { Theme } from './types';
import { log } from 'utils/log';
import { stores } from 'state/stores';

type ThemeType = 'dark' | 'light';

export const MIN_WIDTH_FOR_SIDEBAR_NAVIGATION = 1024;

export const isThemeType = (x: string): x is ThemeType => {
  return ['dark', 'light'].includes(x);
};

const light: Theme = {
  name: 'light',
  primary: colors.neutral900,
  secondary: colors.white,
  accent: colors.primary500,
  background: colors.neutral50,
  border: colors.neutral150,
  neutral50: colors.neutral50,
  neutral100: colors.neutral100,
  neutral150: colors.neutral150,
  neutral200: colors.neutral200,
  neutral300: colors.neutral300,
  neutral400: colors.neutral400,
  neutral500: colors.neutral500,
  neutral600: '',
  neutral700: colors.neutral700,
  neutral800: '',
  neutral900: colors.neutral900,
};

const dark: Theme = {
  name: 'dark',
  primary: colors.white,
  secondary: colors.darkmodeNeutral50,
  accent: colors.primary500,
  background: colors.darkmodeNeutral00,
  border: colors.darkmodeNeutral150,
  neutral50: colors.darkmodeNeutral50,
  neutral100: colors.darkmodeNeutral100,
  neutral150: colors.darkmodeNeutral150,
  neutral200: colors.darkmodeNeutral200,
  neutral300: colors.darkmodeNeutral300,
  neutral400: colors.darkmodeNeutral400,
  neutral500: colors.darkmodeNeutral500,
  neutral600: colors.darkmodeNeutral600,
  neutral700: colors.darkmodeNeutral700,
  neutral800: colors.darkmodeNeutral800,
  neutral900: colors.darkmodeNeutral900,
};

const themes = {
  light,
  dark,
};

export class ThemeState {
  private themeKey: ThemeType = ((): ThemeType => {
    if (!process.browser) return 'light';

    return Cookies.get('theme') === 'dark' ? 'dark' : 'light';
  })();
  private savedKey?: ThemeType;

  constructor() {
    makeAutoObservable(this);
  }

  updateThemeWithDocumentContext = (context: DocumentContext) => {
    const rawCookies = context.req?.headers.cookie;
    const cookies = rawCookies ? cookie.parse(rawCookies) : {};
    const themeCookie = cookies['theme'] as 'dark' | 'light';

    log('🍪 themeCookie: ', themeCookie);

    this.themeKey = ['dark', 'light'].includes(themeCookie) ? themeCookie : 'light';
  };

  get current() {
    // always display light theme if not logged in
    if (!stores.account.username) {
      return themes['light'];
    }

    return themes[this.themeKey];
  }

  onChangeTheme = (newThemeKey: ThemeType) => {
    this.themeKey = newThemeKey;
    Cookies.set('theme', newThemeKey);
  };

  /**
   * saves the current theme in memory. used to force light theme
   * during onboarding and restoring the previous one when done
   */
  push = (newThemeKey: ThemeType) => {
    this.savedKey = this.themeKey;
    this.onChangeTheme(newThemeKey);
  };

  pop = () => {
    if (!this.savedKey) return;
    this.onChangeTheme(this.savedKey);
    this.savedKey = undefined;
  };
}

export const themeState = new ThemeState();
