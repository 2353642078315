import { UserHevyProSubcription } from 'hevy-shared';
import { makeAutoObservable } from 'mobx';
import API from 'utils/API';

export class Subscription {
  hasFetched: boolean = false;
  subscription: UserHevyProSubcription = { is_pro: false };

  get isPro() {
    return this.subscription.is_pro;
  }

  constructor() {
    makeAutoObservable(this);
  }

  fetch = async () => {
    const result = await API.getUserSubscription();
    this.subscription = result.data;
    this.hasFetched = true;
  };

  clearData = () => {
    this.subscription = { is_pro: false };
  };
}
