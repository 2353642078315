import { SearchUser } from 'hevy-shared';
import { makeAutoObservable } from 'mobx';
import toast from 'react-hot-toast';
import { stores } from 'state/stores';
import API from 'utils/API';
import { sendEvent } from 'utils/events';
import { UserListModalViewModalInterface } from './types';

interface FollowingUserListViewModelProps {
  mode: 'following';
  username: string;
}

interface LikesUserListViewModelProps {
  mode: 'likes';
  workoutId: string;
}

type UserListViewModelProps = FollowingUserListViewModelProps | LikesUserListViewModelProps;

export class UserListViewModel implements UserListModalViewModalInterface {
  title: string;
  searchText: string = '';
  _noResultsText;
  isLoading: boolean = false;
  private _users: SearchUser[] = [];
  private fetch: () => Promise<SearchUser[]>;

  constructor(props: UserListViewModelProps) {
    makeAutoObservable(this);

    switch (props.mode) {
      case 'following':
        this.fetch = () => API.getFollowing(props.username).then(res => res.data);
        this.title = 'Following';
        this._noResultsText = `${props.username} is not following anyone yet.`;
        break;
      case 'likes':
        this.fetch = () => {
          if (props.workoutId) {
            return API.getWorkoutLikes(props.workoutId).then(res => res.data);
          } else {
            return Promise.resolve([]);
          }
        };
        this.title = 'Likes';
        this._noResultsText = "No one's liked this workout yet.";
        break;
    }
    this.fetchUsers();
  }

  get users() {
    return this._users.filter(u => {
      return (
        u.username.toLowerCase().includes(this.searchText.toLowerCase()) ||
        u.full_name?.toLowerCase().includes(this.searchText.toLowerCase())
      );
    });
  }

  get noResultsText() {
    if (this.searchText.length > 0) {
      return `No results for "${this.searchText}"`;
    } else {
      return this._noResultsText;
    }
  }

  handleScroll = async () => {
    return;
  };

  private fetchUsers = async () => {
    this.isLoading = true;
    this._users = await this.fetch();
    this.isLoading = false;
  };

  onSearchTextChange = (value: string) => {
    this.searchText = value;
  };

  onFollowUserClick = async (user: SearchUser) => {
    sendEvent('userList_userFollowPress', { userId: user.id });
    try {
      this._users.find(u => u.id === user.id)!.following_status = 'requested';
      await stores.followingStatus.follow(user.username);
    } catch (e) {
      toast.error('Failed to follow user');
    } finally {
      this._users.find(u => u.id === user.id)!.following_status = stores.followingStatus.status(
        user.username,
      );
    }
  };

  onUnfollowUserClick = async (user: SearchUser) => {
    sendEvent('userList_userUnfollowPress', { userId: user.id });
    try {
      this._users.find(u => u.id === user.id)!.following_status = 'not-following';
      await stores.followingStatus.unfollow(user.username);
    } catch {
      toast.error('Failed to unfollow user');
    } finally {
      this._users.find(u => u.id === user.id)!.following_status = stores.followingStatus.status(
        user.username,
      );
    }
  };
}
