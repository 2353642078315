import { makeAutoObservable } from 'mobx';
import API from 'utils/API';

const SERVER_STORAGE_LOCAL_STORAGE_KEY = 'SERVER_STORAGE_LOCAL_STORAGE_KEY';

export class ServerStorage {
  private _serverStorage: Record<string, any> = {};

  constructor() {
    makeAutoObservable(this);
  }

  hydrate = () => {
    const jsonData = window.localStorage.getItem(SERVER_STORAGE_LOCAL_STORAGE_KEY);

    if (jsonData) {
      this._serverStorage = Object.assign({}, JSON.parse(jsonData));
    }
  };

  clearData = () => {
    this._serverStorage = {};
    window.localStorage.removeItem(SERVER_STORAGE_LOCAL_STORAGE_KEY);
  };

  get disableSuggestedUsers() {
    return this._serverStorage['DisableSuggestedUsers'] ?? false;
  }

  fetch = async () => {
    const result = await API.getUserKeyValues();
    this._serverStorage = result.data;
    window.localStorage.setItem(
      SERVER_STORAGE_LOCAL_STORAGE_KEY,
      JSON.stringify(this._serverStorage),
    );
  };
}
