import { LifeCycle } from './stores/lifecycle';
import { Account } from './stores/account';
import { Auth } from './stores/auth';
import { Routines } from './stores/routines';
import { RoutineFolders } from './stores/routineFolders';
import { toJS } from 'mobx';
import { ExerciseTemplates } from './stores/exerciseTemplates';
import { UserPreferences } from './stores/userPreferences';
import { Subscription } from './stores/subscription';
import { RecommendedUsers } from './stores/recommendedUsers';
import { FollowingStatusStore } from './stores/followingStatus';
import { Workouts } from './stores/workouts';
import { PaddlePrices } from './stores/paddlePrices';
import { Coach } from './stores/coach';
import { ExerciseTemplateUnits } from './stores/exerciseTemplateUnits';
import { FollowCounts } from './stores/followCounts';
import { BetaFeatures } from './stores/betaFeatures';
import { ServerStorage } from './stores/serverStorage';

export const stores = {
  lifecycle: new LifeCycle(),
  auth: new Auth(),
  account: new Account(),
  coach: new Coach(),
  subscription: new Subscription(),
  userPreferences: new UserPreferences(),
  routines: new Routines(),
  routineFolders: new RoutineFolders(),
  exerciseTemplates: new ExerciseTemplates(),
  exerciseTemplateUnits: new ExerciseTemplateUnits(),
  workouts: new Workouts(),
  recommendedUsers: new RecommendedUsers(),
  followingStatus: new FollowingStatusStore(),
  paddlePrices: new PaddlePrices(),
  followCounts: new FollowCounts(),
  betaFeatures: new BetaFeatures(),
  serverStorage: new ServerStorage(),
};

if (process.env.NEXT_PUBLIC_ENV !== 'production') {
  (global as any).stores = stores;
  (global as any).toJS = toJS;
}
