import React from 'react';
import { ThemedIcon } from 'styleguide/ThemedIcon';
import { View } from 'styleguide/View';
import { Spacing } from 'styleguide/spacing';
import { logout } from 'utils/logout';

export const LogoutButton = () => {
  return (
    <View style={{ flexDirection: 'row', justifyContent: 'flex-end', flex: 1 }}>
      <View onClick={logout} style={{ cursor: 'pointer' }}>
        <ThemedIcon style={{ paddingTop: Spacing.xs }} type="logout"></ThemedIcon>
      </View>
    </View>
  );
};
