import React from 'react';
import styled from 'styled-components';
import { colors } from 'styleguide/colors';
import { TextSMMedium } from 'styleguide/Texts';

const Container = styled.div`
  padding: 3px 10px;
  border-radius: 7px;
`;

export const CustomExerciseChicklet = () => (
  <Container style={{ backgroundColor: colors.darkGrey }}>
    <TextSMMedium style={{ color: colors.white }}>Custom</TextSMMedium>
  </Container>
);
