import { ProfileImage } from 'components/ProfileImage';
import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { colors } from 'styleguide/colors';
import { Spacing } from 'styleguide/spacing';
import { TextSMMedium, TextSMRegular } from 'styleguide/Texts';
import { View } from 'styleguide/View';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

interface UserHeaderProps {
  username: string;
  date: string;
  profileImage?: string;
  style?: CSSProperties;
}

export const UserHeader = ({ username, date, profileImage, style }: UserHeaderProps) => {
  return (
    <Container style={style}>
      <ProfileImage source={profileImage} diameter={55} />
      <View style={{ marginLeft: Spacing.md }}>
        <TextSMMedium>{username}</TextSMMedium>
        <TextSMRegular style={{ color: colors.neutral400, marginTop: Spacing.xs }}>
          {date}
        </TextSMRegular>
      </View>
    </Container>
  );
};
