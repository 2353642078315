import { makeAutoObservable, toJS } from 'mobx';
import { Routine, RoutineLocationUpdate } from 'hevy-shared';
import API from 'utils/API';
import { moveItemInArray } from 'utils/misc';
import { db } from 'state/indexedDb';

const ROUTINES_LOCAL_STORAGE_KEY = 'ROUTINES_LOCAL_STORAGE_KEY';

export class Routines {
  private _routines: Routine[] = [];

  constructor() {
    makeAutoObservable(this);
  }
  hydrate = async () => {
    // June 17, 2024:
    // We moved storage of routines from window localStorage to indexedDb (so we can use more space),
    // so this line cleans up disk space we're not going to reference anymore.
    // We can remove this in the future at some point
    window.localStorage.removeItem(ROUTINES_LOCAL_STORAGE_KEY);

    this._routines = await db.routines.toArray();
  };

  clearData = async () => {
    this._routines = [];

    await db.routines.clear();
  };

  fetch = async () => {
    const routineIdUpdatedAtMap: { [workout: string]: string } = {};
    const dateOlderThanHevy = '2018-07-31T12:53:33.333Z';

    this._routines.forEach(r => {
      routineIdUpdatedAtMap[r.id] = r.updated_at || dateOlderThanHevy;
    });

    const response = await API.getRoutinesSync(routineIdUpdatedAtMap);

    const newRoutines = this._routines.slice();
    const changes = response.data;

    changes.deleted.forEach(deletedId => {
      const indexToDelete = newRoutines.findIndex(r => r.id === deletedId);

      if (indexToDelete > -1) {
        newRoutines.splice(indexToDelete, 1);
      }
    });

    changes.updated.forEach(updatedRoutine => {
      const indexToUpdate = newRoutines.findIndex(w => w.id === updatedRoutine.id);

      if (indexToUpdate > -1) {
        newRoutines[indexToUpdate] = updatedRoutine;
        return;
      }

      newRoutines.push(updatedRoutine);
    });

    if (changes.updated.length || changes.deleted.length) {
      this._routines = newRoutines;
      await db.routines.clear();
      await db.routines.bulkPut(toJS(this._routines));
    }

    if (response.data.isMore) {
      await this.fetch();
    }
  };

  get routines() {
    // We don't support coach features on hevy-web yet, so we filter them program routines out.
    return this._routines.filter(r => !r.program_id);
  }

  /**
   *
   * @param routineId
   * @returns The routine with the given id.
   * It will first check the local store, and if it doesn't exist there, it will fetch it from the server.
   */
  getRoutine = async (routineId: string): Promise<Routine> => {
    const localRoutine = this.routines.find(r => r.id === routineId);
    if (localRoutine) {
      return localRoutine;
    }

    const routine = (await API.getRoutine(routineId)).data.routine;

    return routine;
  };

  updateRoutineLocation = async (s: RoutineLocationUpdate, d: RoutineLocationUpdate) => {
    if (s.index === d.index && s.folderId === d.folderId) return;

    const previousLocations: RoutineLocationUpdate[] = this.routines.map(r => ({
      routineId: r.id,
      folderId: r.folder_id ?? null,
      index: r.index ?? 0,
    }));

    // Routine was moved to another folder
    if (s.folderId !== d.folderId) {
      const newRoutines: Routine[] = this.routines.map(r => {
        if (r.folder_id !== d.folderId && r.id !== d.routineId) return r;

        // Update the location of the routine that moved
        if (r.id === d.routineId) {
          return {
            ...r,
            folder_id: d.folderId,
            index: d.index,
          };
        }

        // Increment the index by one of all routines with an index that's greater than or equal to the location of the new location.
        if ((r.index ?? 0) >= d.index) {
          return {
            ...r,
            index: (r.index ?? 0) + 1,
          };
        }

        return r;
      });

      this._routines = newRoutines;
    }

    // Routine was moved within it's own folder
    if (s.folderId === d.folderId) {
      const sortedRoutinesInFolder = this.routines
        .filter(r => r.folder_id === d.folderId)
        .sort((r1, r2) => (r1.index ?? 0) - (r2.index ?? 0));

      const newSortedRoutinesInFolder = moveItemInArray(sortedRoutinesInFolder, s.index, d.index);

      for (let i = 0; i < newSortedRoutinesInFolder.length; i++) {
        newSortedRoutinesInFolder[i].index = i;
      }

      this._routines = [
        ...this.routines.filter(r => r.folder_id !== d.folderId),
        ...newSortedRoutinesInFolder,
      ];
    }

    const newLocations: RoutineLocationUpdate[] = this.routines.map(r => ({
      routineId: r.id,
      folderId: r.folder_id ?? null,
      index: r.index ?? 0,
    }));

    const changedLocations = newLocations.filter(nl => {
      const ol = previousLocations.find(ol => ol.routineId === nl.routineId);
      if (!ol) return false;

      return ol.folderId !== nl.folderId || ol.index !== nl.index;
    });

    await API.updateRoutineLocations(changedLocations);
  };

  /**
   * Use findIndexForTopOfFolder to find the index that would put a new routine
   * at the top of a folder. This will always return `-1` or lower.
   */
  findIndexForTopOfFolder = (folderId: number | null): number | null => {
    const folderRoutines = this.routines.filter(r => r.folder_id === folderId);

    const lowestIndex = folderRoutines
      .filter(r => typeof r.index === 'number')
      .reduce((accu: number, r: Routine) => {
        const index = r.index ?? 0;
        return index < accu ? index : accu;
      }, 0);

    return lowestIndex - 1;
  };
}
