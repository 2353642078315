import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import LikeSvg from './assets/like.svg';
import LikeFilledSvg from './assets/like-filled.svg';
import CommentSvg from './assets/comment.svg';
import ShareSvg from './assets/share.svg';
import { DividerLine, DividerLineVertical } from 'styleguide/DividerLine';
import { Spacing } from 'styleguide/spacing';
import { View } from 'styleguide/View';

const Container = styled(View)`
  height: 55px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex: 1;
  padding: ${Spacing.sm}px 0px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

interface ActionsProps {
  isLiked: boolean;
  onLike: () => void;
  onComment?: () => void;
  onShare: () => void;
  style?: CSSProperties;
}

export const WorkoutActions = ({ isLiked, onLike, onComment, onShare, style }: ActionsProps) => {
  return (
    <Container style={style}>
      <DividerLine />
      <ButtonsContainer>
        <ButtonContainer onClick={onLike}>
          {isLiked ? <LikeFilledSvg /> : <LikeSvg />}
        </ButtonContainer>
        <DividerLineVertical style={{ height: 20, alignSelf: 'center' }} />
        {!!onComment && (
          <ButtonContainer onClick={onComment}>
            <CommentSvg />
          </ButtonContainer>
        )}
        <DividerLineVertical style={{ height: 20, alignSelf: 'center' }} />
        <ButtonContainer onClick={onShare}>
          <ShareSvg />
        </ButtonContainer>
      </ButtonsContainer>
      <DividerLine />
    </Container>
  );
};
