import { makeAutoObservable } from 'mobx';
import {
  BodyMeasurementUnit,
  DistanceUnit,
  DistanceUnitShort,
  isBodyMeasurementUnit,
  isDistanceUnit,
  isWeightUnit,
  Language,
  RepInputType,
  UserPreferences as TUserPreferences,
  Weekday,
  WeightUnit,
} from 'hevy-shared';
import API from 'utils/API';

const USER_PREFERENCES_LOCAL_STORAGE_KEY = 'USER_PREFERENCES_LOCAL_STORAGE_KEY';
const LOCAL_USER_PREFERENCES_KEY = 'LOCAL_USER_PREFERENCES_KEY';

type WebUserPreferences = Omit<
  TUserPreferences,
  'username' | 'workout_keep_awake' | 'disabled_review_request' | 'rep_input_type'
>;

interface LocalUserPreferences {
  rep_input_type?: RepInputType;
}

export class UserPreferences {
  private _language?: Language;
  private weight_unit?: WeightUnit;
  private distance_unit?: DistanceUnit;
  private body_measurement_unit?: BodyMeasurementUnit;
  private first_weekday?: Weekday;
  private default_rest_timer_seconds?: number;
  private rep_input_type?: RepInputType;

  constructor() {
    makeAutoObservable(this);
  }

  get language(): Language {
    return this._language || 'en';
  }

  get weightUnit(): WeightUnit {
    return this.weight_unit || 'kg';
  }

  get distanceUnit(): DistanceUnit {
    return this.distance_unit || 'kilometers';
  }

  get distanceUnitShort(): DistanceUnitShort {
    return this.distanceUnit === 'kilometers' ? 'km' : 'mi';
  }

  get bodyMeasurementUnit(): BodyMeasurementUnit {
    return this.body_measurement_unit || 'cm';
  }

  get firstWeekday(): Weekday {
    return this.first_weekday || 'sunday';
  }

  get defaultRestTimerSeconds(): number {
    return this.default_rest_timer_seconds || 0;
  }

  get repInputType(): RepInputType {
    return this.rep_input_type || 'reps';
  }

  setRepInputType = (repInputType: RepInputType) => {
    this.rep_input_type = repInputType;
    window.localStorage.setItem(
      LOCAL_USER_PREFERENCES_KEY,
      JSON.stringify({ rep_input_type: repInputType }),
    );
  };

  update = async (p: Omit<TUserPreferences, 'username'>) => {
    if (p.weight_unit && isWeightUnit(p.weight_unit)) {
      this.weight_unit = p.weight_unit;
    }

    if (p.distance_unit && isDistanceUnit(p.distance_unit)) {
      this.distance_unit = p.distance_unit;
    }

    if (p.body_measurement_unit && isBodyMeasurementUnit(p.body_measurement_unit)) {
      this.body_measurement_unit = p.body_measurement_unit;
    }

    await API.updateUserPreferences(p);
  };

  fetch = async () => {
    const result = await API.getUserPreferences();
    this.updateModel(result.data);
    window.localStorage.setItem(USER_PREFERENCES_LOCAL_STORAGE_KEY, JSON.stringify(result.data));
  };

  hydrate = () => {
    const accountJSON = window.localStorage.getItem(USER_PREFERENCES_LOCAL_STORAGE_KEY);
    if (accountJSON) {
      this.updateModel(JSON.parse(accountJSON));
    }

    const localUserPreferences = window.localStorage.getItem(LOCAL_USER_PREFERENCES_KEY);
    if (localUserPreferences) {
      this.updateLocalModel(JSON.parse(localUserPreferences));
    }
  };

  clearData = () => {
    window.localStorage.removeItem(USER_PREFERENCES_LOCAL_STORAGE_KEY);
    window.localStorage.removeItem(LOCAL_USER_PREFERENCES_KEY);

    const emptyUserPreferences: WebUserPreferences = {};
    this.updateModel(emptyUserPreferences);

    const emptyLocalUserPreferences: LocalUserPreferences = {};
    this.updateLocalModel(emptyLocalUserPreferences);
  };

  private updateModel = (userPreferences: WebUserPreferences) => {
    this._language = userPreferences.language;
    this.weight_unit = userPreferences.weight_unit;
    this.distance_unit = userPreferences.distance_unit;
    this.body_measurement_unit = userPreferences.body_measurement_unit;
    this.first_weekday = userPreferences.first_weekday;
    this.default_rest_timer_seconds = userPreferences.default_rest_timer_seconds;
  };

  private updateLocalModel = (localUserPreferences: LocalUserPreferences) => {
    this.rep_input_type = localUserPreferences.rep_input_type;
  };
}
