import React from 'react';
import styled from 'styled-components';
import { PrimaryButton } from 'styleguide/Buttons';
import { Modal } from 'styleguide/Modal';
import { H1, TextMD } from 'styleguide/Texts';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  border-radius: 10px;
`;

const UpperContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LowerContainer = styled.div`
  display: flex;
  margin-top: 20px;
`;

export interface OkModalProps {
  title: string;
  subtitle: string;
  submitButtonTitle: string;
  isOpen: boolean;
  onClose: () => void;
}

export const OkModal = ({ title, subtitle, submitButtonTitle, isOpen, onClose }: OkModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Container>
        <UpperContainer>
          <H1 style={{ marginBottom: 20 }}>{title}</H1>
          <TextMD>{subtitle}</TextMD>
        </UpperContainer>
        <LowerContainer>
          <PrimaryButton
            style={{ display: 'flex', flex: 1 }}
            title={submitButtonTitle}
            onClick={onClose}
          />
        </LowerContainer>
      </Container>
    </Modal>
  );
};
