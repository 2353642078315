import { FollowingStatus } from 'hevy-shared';
import { makeAutoObservable } from 'mobx';
import API from 'utils/API';
import { log } from 'utils/log';

const FOLLOWING_STATUS_DISK_STORAGE_KEY = 'FOLLOWING_STATUS_DISK_STORAGE_KEY';

export class FollowingStatusStore {
  private statusMap: { [key: string]: 'following' | 'requested' } = {};

  constructor() {
    makeAutoObservable(this);
  }

  hydrate = () => {
    const statusMapJSON = window.localStorage.getItem(FOLLOWING_STATUS_DISK_STORAGE_KEY);
    if (statusMapJSON) {
      this.statusMap = JSON.parse(statusMapJSON);
    }
  };

  clearData = () => {
    this.statusMap = {};
    window.localStorage.removeItem(FOLLOWING_STATUS_DISK_STORAGE_KEY);
  };

  fetch = async () => {
    log('Fetching Following Statuses');
    const result = await API.getFollowingStatuses();
    this.statusMap = result.data;

    // Persist to disk
    window.localStorage.setItem(FOLLOWING_STATUS_DISK_STORAGE_KEY, JSON.stringify(result.data));
  };

  status = (username: string): FollowingStatus => {
    return this.statusMap[username] || 'not-following';
  };

  follow = async (username: string) => {
    try {
      this.statusMap[username] = 'requested';
      await API.followUser(username);
    } catch (e) {
      //
    }

    this.fetch();
  };

  unfollow = async (username: string) => {
    try {
      delete this.statusMap[username];
      await API.unfollowUser(username);
    } catch (e) {
      //
    }

    this.fetch();
  };
}
