import { env } from 'env';
import { pageview, initialize } from 'react-ga';
import { log } from './log';

export const gaInit = () => {
  log('📊 Google Analytics init');
  if (env.env === 'production') {
    initialize('UA-141954737-2');
    pageview(window.location.pathname);
  }
};

export const gaRecordPageview = (url: string) => {
  log('📊 Google Analytics Pageview Event: ', url);
  if (env.env === 'production') {
    pageview(url);
  }
};
