import React, { CSSProperties } from 'react';
import { TextSMRegular } from 'styleguide/Texts';
import { colors } from 'styleguide/colors';
import { splitAtUsernames } from 'utils/pureUtils';
import { Link } from './Link';

interface UserLinkableTextProps {
  text?: string;
  style?: CSSProperties;
  onMentionClick?: () => void;
  textStyle?: CSSProperties;
}

export const UserLinkableText = ({
  text,
  style,
  textStyle,
  onMentionClick,
}: UserLinkableTextProps) => {
  const format = splitAtUsernames(text || '');

  return (
    <div style={{ ...style }}>
      {format.map((f, index) =>
        f.format === '@' ? (
          <Link href={`/user/${f.text.substring(1)}`} key={index}>
            <TextSMRegular
              style={{ ...textStyle, color: colors.primary500, display: 'inline' }}
              onClick={onMentionClick}
            >
              {f.text}
            </TextSMRegular>
          </Link>
        ) : (
          <TextSMRegular key={index} type="secondary" style={{ ...textStyle, display: 'inline' }}>
            {f.text}
          </TextSMRegular>
        ),
      )}
    </div>
  );
};
