import { modal } from 'components/Modals/ModalManager';
import router from 'next/router';
import { stores } from 'state/stores';
import { setAmplitudeUserId } from './events';
import { clearAllUserData } from './logout';
import toast from 'react-hot-toast';

const showCoachInviteModal = () => {
  const { invitingCoachName, invitingCoachProfilePic, acceptInvite, declineInvite } = stores.coach;

  modal.showCoachInviteModal({
    coachName: invitingCoachName,
    coachProfilePic: invitingCoachProfilePic,
    onAcceptInvite: async () => {
      await acceptInvite();

      router.push('/coach').then(() => {
        stores.account.is_coached = true;
        stores.coach.fetch();
      });
    },
    onDeclineInvite: declineInvite,
  });
};

export const fetchUserContentAfterLogin = async () => {
  try {
    /**
     * fetch the Account store first, so we can gracefully log the user out in
     * case the request fails, instead of showing a broken UI with no nav bar
     */
    await stores.account.fetch();
  } catch (e) {
    clearAllUserData();
    router
      .push('/login')
      .then(() => toast.error('There was an error while trying to log in. Please try again.'));
    throw e;
  }

  Promise.all([
    stores.userPreferences.fetch(),
    stores.serverStorage.fetch(),
    stores.routines.fetch(),
    stores.exerciseTemplates.fetch(),
    stores.exerciseTemplateUnits.fetch(),
    stores.routineFolders.fetch(),
    stores.subscription.fetch(),
    stores.coach.fetch(),
  ]).then(() => {
    if (stores.coach.isInvitedByCoach) {
      showCoachInviteModal();
    }
  });

  setAmplitudeUserId(stores.account.id);
};

export const fetchUserContentAfterLaunch = async () => {
  await Promise.all([
    stores.account.fetch(),
    stores.userPreferences.fetch(),
    stores.serverStorage.fetch(),
  ]);

  stores.coach.fetch().then(() => {
    if (stores.coach.isInvitedByCoach) {
      showCoachInviteModal();
    }
  });

  if (stores.coach.isInvitedByCoach) {
    showCoachInviteModal();
  }

  setAmplitudeUserId(stores.account.id);
};
