import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { stores } from 'state/stores';
import styled, { ThemeProps } from 'styled-components';
import { TextMD } from 'styleguide/Texts';
import { Theme } from 'styleguide/types';
import { FeedbackModal } from './Modals/FeedbackModal';

const Container = styled.div`
  position: fixed;
  right: -35px;
  bottom: 200px;
  height: 30px;
  width: 100px;
  transform: rotate(270deg);
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  z-index: 1;
`;

const TextContainer = styled.div`
  background-color: ${(props: ThemeProps<Theme>) => props.theme.secondary};
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const FeedbackTag = observer(() => {
  return stores.account.username ? <FeedbackTagInner /> : <div />;
});

const FeedbackTagInner = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Container>
      <TextContainer onClick={() => setIsModalOpen(true)}>
        <TextMD>Feedback</TextMD>
      </TextContainer>
      <FeedbackModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
      />
    </Container>
  );
};
