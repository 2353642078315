import { makeAutoObservable } from 'mobx';
import { ExerciseTemplateUnit, WeightUnit } from 'hevy-shared';
import API from 'utils/API';

const EXERCISE_TEMPLATE_UNITS_LOCAL_STORAGE_KEY = 'EXERCISE_TEMPLATE_UNITS_LOCAL_STORAGE_KEY';

export class ExerciseTemplateUnits {
  private exerciseTemplateUnits: ExerciseTemplateUnit[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  exerciseUnit(exerciseTemplateId: string): WeightUnit | undefined {
    return this.exerciseTemplateUnits.find(etu => etu.exercise_template_id === exerciseTemplateId)
      ?.weight_unit;
  }

  hydrate = () => {
    const exerciseTemplateUnitsJson = window.localStorage.getItem(
      EXERCISE_TEMPLATE_UNITS_LOCAL_STORAGE_KEY,
    );
    if (exerciseTemplateUnitsJson) {
      this.exerciseTemplateUnits = JSON.parse(exerciseTemplateUnitsJson);

      window.localStorage.setItem(
        EXERCISE_TEMPLATE_UNITS_LOCAL_STORAGE_KEY,
        JSON.stringify(this.exerciseTemplateUnits),
      );
    }
  };

  clearData = () => {
    this.exerciseTemplateUnits = [];
    window.localStorage.removeItem(EXERCISE_TEMPLATE_UNITS_LOCAL_STORAGE_KEY);
  };

  fetch = async () => {
    const result = await API.getExerciseTemplateUnits();
    this.exerciseTemplateUnits = result.data;
    window.localStorage.setItem(
      EXERCISE_TEMPLATE_UNITS_LOCAL_STORAGE_KEY,
      JSON.stringify(this.exerciseTemplateUnits),
    );
  };
}
