import { ThemedHevyProLogo } from 'components/svg/ThemedHevyProLogo';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import React, { CSSProperties } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { PrimaryButton, SecondaryButton } from 'styleguide/Buttons';
import { Modal } from 'styleguide/Modal';
import { H1, TextMD } from 'styleguide/Texts';
import { themeState } from 'styleguide/theme';
import { View } from 'styleguide/View';
import { sendEvent } from 'utils/events';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  border-radius: 10px;
`;

const UpperContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LowerContainer = styled.div`
  margin-top: 10px;
`;

export type PaywallModalType =
  | 'routine-limit'
  | 'history-limit'
  | 'exercise-limit'
  | 'no-api-access';

export interface PaywallModalProps {
  type: PaywallModalType;
  isOpen: boolean;
  onClose: () => void;
  onCancel: () => void;
}

export const PaywallModal = observer(({ type, isOpen, onClose, onCancel }: PaywallModalProps) => {
  const router = useRouter();

  const textMap: { [key in PaywallModalType]: { title: string; subtitle: string } } = {
    'routine-limit': {
      title: 'Routine Limit Reached',
      subtitle: 'Free accounts are limited to 4 routines. Upgrade to Hevy Pro to get unlimited.',
    },
    'history-limit': {
      title: 'Data Limited to 3 months',
      subtitle:
        'Free accounts are limited to 3 months of exercise analytics. Upgrade to Hevy Pro to get unlimited.',
    },
    'exercise-limit': {
      title: 'Custom Exercise Limit Reached',
      subtitle: 'Free accounts are limited to 7 custom. Upgrade to Hevy Pro to get unlimited.',
    },
    'no-api-access': {
      title: 'Our API is only available with Hevy Pro',
      subtitle: 'Upgrade to Hevy Pro to unlock developer tools.',
    },
  };

  return (
    <ThemeProvider theme={themeState.current}>
      <Modal isOpen={isOpen} onClose={onCancel}>
        <Container>
          <UpperContainer>
            <View style={{ padding: 20 }}>
              <ThemedHevyProLogo />
            </View>
            <H1 style={{ marginBottom: 20 }}>{textMap[type].title}</H1>
            <TextMD>{textMap[type].subtitle}</TextMD>
          </UpperContainer>
          <LowerContainer>
            <PrimaryButton
              style={styles.button}
              title={'Continue'}
              onClick={() => {
                sendEvent('paywall_modal_continue_clicked', { type });
                onClose();
                router.push(`/plans?continue=${router.asPath}`);
              }}
            />
            <SecondaryButton style={styles.button} title={'Not now'} onClick={onCancel} />
          </LowerContainer>
        </Container>
      </Modal>
    </ThemeProvider>
  );
});

const styles: { [key: string]: CSSProperties } = {
  button: {
    width: '100%',
    marginTop: '10px',
  },
};
