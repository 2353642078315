import React, { CSSProperties } from 'react';
import styled, { ThemeProps, css } from 'styled-components';
import { colors } from 'styleguide/colors';
import { Spacing } from 'styleguide/spacing';
import { View } from 'styleguide/View';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import ReactModal from 'react-modal';
import useScreenDimensions from 'utils/useScreenDimensions';
import { NavBarMainContent } from './components/NavBarMainContent';
import { themeState } from 'styleguide/theme';
import { ThemedLogo } from 'components/svg/ThemedLogo';
import { NavBarUserSearchViewModel } from './components/NavBarUserSearchViewModel';
import { Theme } from 'styleguide/types';
import { Icon } from 'styleguide/Icon';
import { FlexRow } from 'styleguide/Row';

const NavBarContainer = styled(View)`
  position: sticky;
  bottom: 0;
  height: 100%;
  border-top-width: 0;
  border-bottom-width: 0;
  box-sizing: border-box;
  ${(props: ThemeProps<Theme>) =>
    props.theme.name === 'light' &&
    css`
      border-right-style: solid;
      border-right-width: 1px;
      border-right-color: ${colors.neutral150};
    `}
`;

const LogoContainer = styled.div`
  padding: ${Spacing.lg}px ${Spacing.sm}px ${Spacing.sm}px ${Spacing.sm}px;
`;

const modalStyle: CSSProperties = {
  top: 0,
  left: 0,
  flex: 1,
  display: 'flex',
  borderRadius: 0,
  height: '100svh',
  margin: 0,
  padding: 0,
  width: '256px',
  border: 0,
  backgroundColor: 'transparent',
};

const overlayStyle: CSSProperties = {
  backgroundColor: 'rgba(16, 18, 19, 0.3)',
  zIndex: 1002,
};

export interface MobileNavBarProps {
  isOpen: boolean;
  onClose: () => void;
}

export const MobileNavBarModal = (props: MobileNavBarProps) => {
  const searchVm = new NavBarUserSearchViewModel();
  return <MobileNavBarModalInner {...props} vm={searchVm} />;
};
const MobileNavBarModalInner = observer(
  ({ isOpen, onClose, vm }: MobileNavBarProps & { vm: NavBarUserSearchViewModel }) => {
    const pathname = useRouter().pathname;

    const windowSize = useScreenDimensions();

    return (
      <ReactModal
        isOpen={isOpen && windowSize.width < 1024}
        onRequestClose={onClose}
        style={{
          content: {
            ...modalStyle,
            overflow: 'visible',
          },
          overlay: overlayStyle,
        }}
      >
        <NavBarContainer
          style={{
            borderRightColor: themeState.current.border,
            backgroundColor: themeState.current.secondary,
          }}
        >
          <FlexRow style={{ justifyContent: 'space-between' }}>
            <LogoContainer onClick={onClose}>
              <ThemedLogo />
            </LogoContainer>
            <View onClick={onClose} style={{ marginRight: Spacing.md, cursor: 'pointer' }}>
              <Icon type="xSmall"></Icon>
            </View>
          </FlexRow>

          <NavBarMainContent
            vm={vm}
            pathname={pathname}
            isSettingsEnabled={false}
            showProCta={false}
          />
        </NavBarContainer>
      </ReactModal>
    );
  },
);
