import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { ExercisePlaceholderSvg } from './ExercisePlaceholderSvg';
import { colors } from 'styleguide/colors';

interface ExerciseImageProps {
  source?: string;
  style?: CSSProperties;
  diameter?: number;
}

interface ImageProps {
  diameter: number;
}

const Image = styled.img`
  height: ${(props: ImageProps) => `${props.diameter}px`};
  width: ${(props: ImageProps) => `${props.diameter}px`};
  border-radius: 50%;
`;

export const ExerciseImage = ({ source, diameter, style }: ExerciseImageProps) => {
  return source ? (
    <Image
      style={{
        ...{
          borderRadius: diameter ? diameter / 2 : 17,
          borderStyle: 'solid',
          borderColor: colors.neutral150,
          borderWidth: 1,
          overflow: 'hidden',
        },
        ...style,
      }}
      diameter={diameter ?? 34}
      src={source}
    />
  ) : (
    <div style={style}>
      <ExercisePlaceholderSvg diameter={diameter ?? 34} />
    </div>
  );
};
