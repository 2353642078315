import { SearchInput } from 'components/SearchInput';
import { observer } from 'mobx-react-lite';
import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { DividerLine } from 'styleguide/DividerLine';
import { Icon } from 'styleguide/Icon';
import { Modal } from 'styleguide/Modal';
import { TextLG, TextMD, TextSMMedium } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { themeState } from 'styleguide/theme';
import { ProfileImage } from 'components/ProfileImage';
import { PrimaryButton } from 'styleguide/Buttons';
import { SecondaryButton } from 'styleguide/Buttons';
import { stores } from 'state/stores';
import { ClipLoader } from 'react-spinners';
import { colors } from 'styleguide/colors';
import { UserListModalViewModalInterface } from './types';
import { Link } from 'components/Link';

const UpperContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 24px 16px 24px;
`;

const LowerContainer = styled(View)`
  padding: 16px 24px;
  overflow-y: auto;
  gap: 16px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 80vh;
  max-width: 500px;
  @media (max-width: 1024px) {
    width: 80vw;
  }
  border-radius: 10px;
`;

const UserHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

export interface UserListModalProps {
  vm: UserListModalViewModalInterface;
  isOpen: boolean;
  onClose: () => void;
}

export const UserListModal = observer(({ vm, isOpen, onClose }: UserListModalProps) => {
  return (
    <ThemeProvider theme={themeState.current}>
      <Modal isOpen={isOpen} onClose={onClose} contentStyle={{ padding: 0 }}>
        <Container style={{ colorScheme: themeState.current.name }}>
          <UpperContainer>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                marginBottom: 8,
              }}
            >
              <TextLG style={{ fontWeight: 500 }}>{vm.title}</TextLG>
              <View style={{ cursor: 'pointer' }} onClick={onClose}>
                <Icon type="xSmallGrey"></Icon>
              </View>
            </View>
            <SearchInput
              style={{ width: '100%' }}
              value={vm.searchText}
              type={'primary'}
              placeholder={'Search users'}
              onChange={vm.onSearchTextChange}
            ></SearchInput>
          </UpperContainer>
          <DividerLine></DividerLine>
          <LowerContainer onScroll={vm.handleScroll}>
            {vm.users.length ? (
              vm.users.map(user => {
                return (
                  <>
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Link href={`/user/${user.username}`}>
                        <UserHeader
                          onClick={() => {
                            onClose();
                          }}
                        >
                          <ProfileImage
                            source={user.profile_pic}
                            diameter={48}
                            style={{ marginRight: 16 }}
                          ></ProfileImage>
                          <View style={{ flexDirection: 'column' }}>
                            <TextMD>{user.username}</TextMD>
                            <TextSMMedium style={{ color: '#6C737F' }}>
                              {user.full_name}
                            </TextSMMedium>
                          </View>
                        </UserHeader>
                      </Link>
                      {user.username !== stores.account.username &&
                        (user.following_status === 'following' ? (
                          <SecondaryButton
                            title="Unfollow"
                            style={{ padding: 16 }}
                            onClick={() => vm.onUnfollowUserClick(user)}
                          ></SecondaryButton>
                        ) : user.following_status === 'not-following' ? (
                          <PrimaryButton
                            title="Follow"
                            style={{ padding: 16 }}
                            onClick={() => vm.onFollowUserClick(user)}
                          ></PrimaryButton>
                        ) : (
                          <SecondaryButton
                            title="Pending"
                            style={{ padding: 16 }}
                          ></SecondaryButton>
                        ))}
                    </View>

                    <div style={vm.isLoading || !isOpen ? { display: 'none' } : {}} />
                  </>
                );
              })
            ) : (
              <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                {vm.isLoading ? (
                  <ClipLoader size={30} color={colors.primary500} loading={true} />
                ) : (
                  <TextMD>{vm.noResultsText}</TextMD>
                )}
              </View>
            )}
          </LowerContainer>
        </Container>
      </Modal>
    </ThemeProvider>
  );
});
