import styled, { ThemeProps } from 'styled-components';
import { colors } from 'styleguide/colors';
import fonts from 'styleguide/fonts';
import { Theme } from 'styleguide/types';

export const Select = styled.select`
  padding: 6px 10px 6px 10px;
  font-family: ${fonts.regular.family};
  font-size: 16px;
  border-radius: 7px;
  appearance: none;
  outline: none;
  background-image: url("data:image/svg+xml;utf8,<?xml version='1.0' encoding='UTF-8'?><svg width='30px' height='30px' viewBox='0 0 30 30' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><title>chevron-down-accent</title><g id='chevron-down-accent' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round'><polyline id='Path-23' stroke='%231D83EA' stroke-width='1.5' points='11 13 15.3044891 17.3044891 19.6089783 13'></polyline></g></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-color: ${(props: ThemeProps<Theme>) => props.theme.secondary};
  color: ${(props: ThemeProps<Theme>) => props.theme.primary};
  border: 1px solid
    ${(props: ThemeProps<Theme>) =>
      props.theme.name === 'light' ? props.theme.border : colors.darkmodeNeutral200};
`;
