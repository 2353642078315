import { makeAutoObservable } from 'mobx';
import { isProduction } from 'utils/misc';

const BETA_FEATURES_LOCAL_STORAGE_KEY = 'BETA_FEATURES_LOCAL_STORAGE_KEY';

export class BetaFeatures {
  private _areBetaFeaturesEnabled = false;
  constructor() {
    makeAutoObservable(this);
  }

  hydrate = () => {
    this._areBetaFeaturesEnabled = Boolean(
      window.localStorage.getItem(BETA_FEATURES_LOCAL_STORAGE_KEY) ?? false,
    );
  };

  clearData = () => {
    this._areBetaFeaturesEnabled = false;
    window.localStorage.removeItem(BETA_FEATURES_LOCAL_STORAGE_KEY);
  };

  get areBetaFeaturesEnabled() {
    return !isProduction() || this._areBetaFeaturesEnabled;
  }

  set areBetaFeaturesEnabled(value: boolean) {
    window.localStorage.setItem(BETA_FEATURES_LOCAL_STORAGE_KEY, value.toString());
    this._areBetaFeaturesEnabled = value;
  }
}
