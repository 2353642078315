import { makeAutoObservable } from 'mobx';
import { Sex, UserAccountResponse } from 'hevy-shared';
import API from 'utils/API';
import { captureException } from '@sentry/nextjs';

const ACCOUNT_LOCAL_STORAGE_KEY = 'ACCOUNT_LOCAL_STORAGE_KEY';

export class Account implements UserAccountResponse {
  id: string = '';
  username: string = '';
  email: string = '';
  full_name?: string;
  description?: string;
  website_link?: string | undefined;
  likes_push_enabled: boolean = true;
  follows_push_enabled: boolean = true;
  comments_push_enabled: boolean = true;
  comment_mention_push_enabled: boolean = true;
  comment_discussion_push_enabled: boolean = true;
  private_profile: boolean = false;
  profile_pic?: string;
  gift_pro_expiry?: string;
  created_at: string = '';
  last_workout_at: string = '';
  accepted_terms_and_conditions: boolean = false;
  /**
   * the reason why this is here in the `account` store instead of the `coach`
   * store is because we want to know if the user is coached as soon as they
   * are logged in, to prevent flickering of the nav bar.
   */
  is_coached: boolean = false;
  is_a_coach: boolean = false;
  gympass_user_id?: string;
  is_gympass_plan_active?: boolean;
  birthday?: string;
  sex?: Sex;
  is_strava_connected: boolean = false;
  email_consent: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  fetch = async () => {
    try {
      const result = await API.getAccount();
      this.updateModel(result.data);
      window.localStorage.setItem(ACCOUNT_LOCAL_STORAGE_KEY, JSON.stringify(result.data));
    } catch (e) {
      /**
       * if fetching fails and we don't already have an account in local
       * storage, the nav bar will be empty and a bogus welcome state will be
       * shown. for now, just track this until we figure out why the request is
       * failing and confirm that this is indeed the main cause.
       */
      captureException(e);
      throw e;
    }
  };

  hydrate = () => {
    const accountJSON = window.localStorage.getItem(ACCOUNT_LOCAL_STORAGE_KEY);
    if (accountJSON) {
      this.updateModel(JSON.parse(accountJSON));
    }
  };

  clearData = () => {
    window.localStorage.removeItem(ACCOUNT_LOCAL_STORAGE_KEY);
    const emptyAccount: UserAccountResponse = {
      id: '',
      username: '',
      email: '',
      likes_push_enabled: false,
      follows_push_enabled: false,
      comments_push_enabled: false,
      comment_mention_push_enabled: false,
      comment_discussion_push_enabled: false,
      private_profile: false,
      created_at: '',
      last_workout_at: '',
      accepted_terms_and_conditions: false,
      is_coached: false,
      is_a_coach: false,
      is_strava_connected: false,
      email_consent: false,
    };

    this.updateModel(emptyAccount);
  };

  private updateModel = (account: UserAccountResponse) => {
    this.id = account.id;
    this.username = account.username;
    this.email = account.email;
    this.full_name = account.full_name;
    this.description = account.description;
    this.website_link = account.website_link;
    this.likes_push_enabled = account.likes_push_enabled;
    this.follows_push_enabled = account.follows_push_enabled;
    this.comments_push_enabled = account.comments_push_enabled;
    this.comment_mention_push_enabled = !!account.comment_mention_push_enabled;
    this.comment_discussion_push_enabled = !!account.comment_discussion_push_enabled;
    this.gift_pro_expiry = account.gift_pro_expiry;
    this.private_profile = account.private_profile;
    this.profile_pic = account.profile_pic;
    this.created_at = account.created_at;
    this.last_workout_at = account.last_workout_at;
    this.is_coached = account.is_coached;
    this.is_a_coach = account.is_a_coach;
    this.gympass_user_id = account.gympass_user_id;
    this.is_gympass_plan_active = account.is_gympass_plan_active;
    this.birthday = account.birthday;
    this.sex = account.sex;
    this.is_strava_connected = account.is_strava_connected;
    this.email_consent = account.email_consent;
  };
}
