import { FollowCountsResponse } from 'hevy-shared';
import { makeAutoObservable } from 'mobx';
import API from 'utils/API';

const FOLLOW_COUNTS_STORAGE_KEY = 'FOLLOW_COUNTS_STORAGE_KEY';

export class FollowCounts {
  following_count: number = 0;
  follower_count: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  hydrate = () => {
    const followCountsJSON = window.localStorage.getItem(FOLLOW_COUNTS_STORAGE_KEY);
    if (followCountsJSON) {
      const followCounts: FollowCountsResponse = JSON.parse(followCountsJSON);

      this.following_count = followCounts.following_count;
      this.follower_count = followCounts.follower_count;
    }
  };

  fetch = async () => {
    const result = await API.getFollowCounts();

    this.following_count = result.data.following_count;
    this.follower_count = result.data.follower_count;

    this.persist();
  };

  persist = () => {
    const newFollowCounts: FollowCountsResponse = {
      following_count: this.following_count,
      follower_count: this.follower_count,
    };

    window.localStorage.setItem(FOLLOW_COUNTS_STORAGE_KEY, JSON.stringify(newFollowCounts));
  };

  clearData = () => {
    this.follower_count = 0;
    this.following_count = 0;
  };
}
