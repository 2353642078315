import dayjs, { Dayjs } from 'dayjs';
import { env } from 'env';
import { colors } from 'styleguide/colors';

export const FORMATTED_DATE_FORMAT = 'D MMM YYYY, HH:mm';

export const isProduction = () => {
  return env.env === 'production';
};

export const moveItemInArray = <T>(arr: T[], fromIndex: number, toIndex: number) => {
  const element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
  return arr;
};

export const getRelativeDate = (date: Dayjs) => {
  const now = dayjs();
  const yesterday = dayjs().subtract(1, 'day');

  if (now.isSame(date, 'day')) {
    return date.fromNow();
  }

  if (yesterday.isSame(date, 'day')) {
    return `Yesterday at ${date.format('h:mma')}`;
  }

  return date.format(FORMATTED_DATE_FORMAT);
};

export const getSupersetColor = (id: number): string => {
  const normalizedId = id % colors.supersets.length;
  return colors.supersets[normalizedId].bold;
};

export const formatLink = (url: string) => {
  if (url.startsWith('https://') || url.startsWith('http://')) {
    return url;
  } else {
    return 'https://' + url;
  }
};
