import React from 'react';
import styled from 'styled-components';
import { Icon } from 'styleguide/Icon';
import { Modal } from 'styleguide/Modal';
import { ExerciseLibraryViewModel } from '../ExerciseLibrary/ExerciseLibraryViewModel';
import { View } from 'styleguide/View';
import { TextLG, TextMD, TextSMMedium } from 'styleguide/Texts';
import { CornerRadius, Spacing } from 'styleguide/spacing';
import { PrimaryButton, TertiaryButton } from 'styleguide/Buttons';
import { Select } from 'components/Select';
import { equipmentFilters, isExerciseTemplate, muscleGroupFilters } from 'hevy-shared';
import { equipmentFilterToHuman, muscleGroupFilterToHuman } from 'utils/exerciseUtils';
import { SearchInput } from 'components/SearchInput';
import { AddExerciseCell } from 'components/ExerciseLibrary/components/AddExerciseCell';
import { DividerLine } from 'styleguide/DividerLine';
import { Observer } from 'mobx-react-lite';

const Container = styled(View)`
  width: 500px;
  flex: 1;
`;

const DismissButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  padding: ${Spacing.xs}px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${Spacing.md}px;
`;

const ExercisesListContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;
`;

export interface ExerciseLibraryModalProps {
  vm: ExerciseLibraryViewModel;
  hidePlusIcons?: boolean;
  onSelectExercise?: (templateId: string) => void;
  onCreateExercise?: () => void;
  selectedExerciseId?: string;
  isOpen: boolean;
  onClose: () => void;
}

export const ExerciseLibraryModal = ({
  vm,
  hidePlusIcons,
  selectedExerciseId,
  onSelectExercise,
  onCreateExercise,
  isOpen,
  onClose,
}: ExerciseLibraryModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} contentStyle={{ height: '100%', padding: 0 }}>
      <Observer>
        {() => (
          <Container>
            <TopContainer>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginBottom: Spacing.md,
                  justifyContent: 'space-between',
                }}
              >
                <TitleContainer>
                  <TextLG>Select Exercise</TextLG>
                </TitleContainer>
                {onCreateExercise && (
                  <TertiaryButton
                    onClick={() => {
                      if (onCreateExercise) onCreateExercise();
                      onClose();
                    }}
                  >
                    + Custom Exercise
                  </TertiaryButton>
                )}
                <DismissButtonContainer onClick={onClose}>
                  <Icon type="xSmallGrey" />
                </DismissButtonContainer>
              </View>
              <Select onChange={f => vm.onEquipmentFilterUpdate(f.currentTarget.value)}>
                {equipmentFilters.map(f => (
                  <option key={f} value={f}>
                    {equipmentFilterToHuman(f)}
                  </option>
                ))}
              </Select>
              <Select
                style={{ marginTop: Spacing.md }}
                onChange={f => vm.onMuscleGroupFilterUpdate(f.currentTarget.value)}
              >
                {muscleGroupFilters.map(f => (
                  <option key={f} value={f}>
                    {muscleGroupFilterToHuman(f)}
                  </option>
                ))}
              </Select>

              <SearchInput
                style={{ marginTop: Spacing.md }}
                type="primary"
                value={vm.searchText}
                onChange={value => {
                  vm.onSearchTextUpdate(value);
                }}
                placeholder="Search Exercises"
              />
            </TopContainer>
            <DividerLine></DividerLine>
            <ExercisesListContainer>
              <View
                style={{
                  flex: 1,
                  marginTop: Spacing.md,
                  marginBottom: Spacing.md,
                  outline: 'none',
                  backgroundColor: 'transparent',
                }}
              >
                {vm.data.map(e => {
                  if (isExerciseTemplate(e)) {
                    return (
                      <AddExerciseCell
                        key={e.key}
                        highlight={e.id === selectedExerciseId}
                        image={e.thumbnail_url}
                        title={e.title}
                        muscleGroup={e.muscle_group}
                        isCustom={e.is_custom}
                        hidePlusIcons={hidePlusIcons}
                        onClick={() => {
                          if (onSelectExercise) onSelectExercise(e.id);
                          onClose();
                        }}
                        style={
                          hidePlusIcons
                            ? {
                                borderRadius: CornerRadius.md,
                                marginRight: Spacing.sm,
                                marginLeft: Spacing.sm,
                              }
                            : {}
                        }
                      />
                    );
                  }

                  switch (e.type) {
                    case 'section':
                      return (
                        <View
                          style={{
                            marginRight: Spacing.md,
                            marginLeft: Spacing.md,
                            marginBottom: Spacing.sm,
                          }}
                        >
                          <TextMD type="secondary">{e.title}</TextMD>
                        </View>
                      );
                    case 'spacer':
                      return (
                        <DividerLine style={{ marginTop: Spacing.sm, marginBottom: Spacing.md }} />
                      );
                    case 'no-exercises-match-search':
                      return (
                        <View
                          style={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginBottom: Spacing.md,
                            padding: Spacing.md,
                          }}
                        >
                          <TextMD
                            style={{
                              textAlign: 'center',
                              marginBottom: Spacing.sm,
                              marginTop: Spacing.md,
                            }}
                          >
                            Can’t find {vm.searchText}
                          </TextMD>
                          <TextSMMedium style={{ textAlign: 'center', marginBottom: Spacing.sm }}>
                            We don’t have that exercise in our database yet.
                          </TextSMMedium>
                          <PrimaryButton
                            style={{ marginTop: Spacing.md, padding: '0 16px' }}
                            title="Create New Exercise"
                            onClick={() => {
                              if (onCreateExercise) onCreateExercise();
                              onClose();
                            }}
                          />
                        </View>
                      );
                  }
                })}
              </View>
            </ExercisesListContainer>
          </Container>
        )}
      </Observer>
    </Modal>
  );
};
