import styled, { ThemeProps } from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import { colors } from './colors';
import fonts from './fonts';
import { Theme } from './types';
import { TextSMMedium } from './Texts';
import React, { CSSProperties } from 'react';
import CheckboxSelected from './assets/checkbox-selected.svg';
import CheckboxEmpty from './assets/checkbox-empty.svg';
import RadiobuttonSelected from './assets/radiobutton-selected.svg';
import RadiobuttonEmpty from './assets/radiobutton-empty.svg';
import ErrorIcon from './assets/icon-input-error.svg';
import { Spacing } from './spacing';

export const Input = styled.input`
  outline: none;
  border: none;
  font-family: ${fonts.regular.family};
  font-weight: ${fonts.regular.weight};
  color: ${(props: ThemeProps<Theme>) => props.theme.primary};
  font-size: 16px;
  ::placeholder {
    color: ${colors.neutral400};
    opacity: 1; /* Firefox */
  }
`;

export const TextArea = styled(TextareaAutosize)`
  resize: none;
  outline: none;
  border: none;
  font-family: ${fonts.regular.family};
  font-weight: ${fonts.regular.weight};
  color: ${(props: ThemeProps<Theme>) => props.theme.primary};
  font-size: 16px;
  background-color: ${colors.transparent};
  ::placeholder {
    color: ${colors.neutral400};
    opacity: 1; /* Firefox */
  }
`;

export const FormInput = styled.input`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px 20px;
  border-radius: 6px;
  box-sizing: border-box;
  font-size: 16px;
  outline: none;
  color: ${(props: ThemeProps<Theme>) => props.theme.primary};
  font-family: ${fonts.regular.family};
  font-weight: ${fonts.regular.weight};
  background-color: ${(props: ThemeProps<Theme>) => props.theme.secondary};
  border: 1px solid ${(props: ThemeProps<Theme>) => props.theme.border};
  :focus {
    border: 1px solid ${colors.primary500};
    outline: 1px solid ${colors.primary500};
  }

  ::placeholder {
    color: ${colors.neutral400};
    opacity: 1; /* Firefox */
  }
`;

export const ExerciseNoteInput = styled.textarea`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  width: 100%;
  padding: ${Spacing.md}px ${Spacing.md}px ${0}px ${Spacing.md}px;
  @media (max-width: 1024px) {
    padding: ${Spacing.sm}px ${Spacing.sm}px;
  }
  height: inherit;
  border-radius: 6px;
  box-sizing: border-box;
  resize: vertical;
  font-size: 16px;
  outline: none;
  font-family: ${fonts.regular.family};
  font-weight: ${fonts.regular.weight};
  color: ${(props: ThemeProps<Theme>) => props.theme.primary};
  background-color: ${(props: ThemeProps<Theme>) => props.theme.secondary};
  border: 1px solid ${(props: ThemeProps<Theme>) => props.theme.border};
  :focus {
    border: 1px solid ${colors.primary500};
    outline: 1px solid ${colors.primary500};
  }

  ::placeholder {
    color: ${colors.neutral400};
    opacity: 1; /* Firefox */
  }
`;

export const LabelledFormInput = (
  props: {
    label: string;
    containerStyle?: CSSProperties;
    errorMessage?: string;
    disabled?: boolean;
    onReturnPressed?: () => void;
  } & React.ComponentPropsWithRef<'input'>,
) => (
  <div style={{ position: 'relative', ...props.containerStyle }}>
    <TextSMMedium style={{ marginTop: '12px', marginBottom: '4px' }}>{props.label}</TextSMMedium>
    <FormInput
      onKeyDown={e => {
        if (e.key === 'Enter') {
          props.onReturnPressed?.();
        }
      }}
      style={{
        opacity: props.disabled ? 0.5 : 1.0,
        border: !!props.errorMessage ? `1px solid ${colors.red}` : undefined,
        outline: !!props.errorMessage ? `1px solid ${colors.red}` : undefined,
      }}
      {...props}
    />
    {props.errorMessage && (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '4px',
          marginTop: '6px',
          marginLeft: '3px',
        }}
      >
        <ErrorIcon />
        <TextSMMedium style={{ color: colors.red }}>{props.errorMessage}</TextSMMedium>
      </div>
    )}
  </div>
);

export const StyledCheckbox = (props: {
  label?: string | JSX.Element;
  checked: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  style?: React.ComponentPropsWithRef<'label'>['style'];
  errorMessage?: string;
}) => (
  <div style={props.style}>
    <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
      <FormInput
        type={'checkbox'}
        checked={props.checked}
        onChange={props.onChange}
        style={{ display: 'none' }}
      />
      <div
        style={{
          display: 'inline',
          width: 24,
          height: 24,
          paddingRight: 8,
        }}
      >
        {props.checked ? <CheckboxSelected /> : <CheckboxEmpty />}
      </div>
      {props.label && <div style={{ display: 'inline', width: '100%' }}>{props.label}</div>}
    </label>
    {props.errorMessage && (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '4px',
          marginTop: '6px',
          marginLeft: '3px',
        }}
      >
        <ErrorIcon />
        <TextSMMedium style={{ color: colors.red }}>{props.errorMessage}</TextSMMedium>
      </div>
    )}
  </div>
);

export const StyledRadioButton = (props: {
  label?: string | JSX.Element;
  checked: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  style?: React.ComponentPropsWithRef<'label'>['style'];
}) => (
  <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', ...props.style }}>
    <FormInput
      type={'radiobutton'}
      checked={props.checked}
      onChange={props.onChange}
      style={{ display: 'none' }}
    />
    <div
      style={{
        display: 'inline',
        width: 24,
        height: 24,
        paddingRight: 8,
      }}
    >
      {props.checked ? <RadiobuttonSelected /> : <RadiobuttonEmpty />}
    </div>
    {props.label && <div style={{ display: 'inline', width: '100%' }}>{props.label}</div>}
  </label>
);
