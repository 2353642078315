import * as Sentry from '@sentry/nextjs';
import { autorun } from 'mobx';
import { stores } from 'state/stores';

export const setSentryUserData = () => {
  console.log('🕷 Initialising Sentry user data autorun');

  autorun(() => {
    const { id, username, email } = stores.account;
    console.log('🕷 Setting Sentry user data');
    Sentry.setUser({ id, username, email });
  });
};
