import React from 'react';

interface Props {
  diameter: number;
}

export function ExercisePlaceholderSvg(props: Props) {
  return (
    <svg
      width={props.diameter}
      height={props.diameter}
      viewBox="0 0 50 50"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <circle fill="#4A4A4A" cx={25} cy={25} r={25} />
        <path
          d="M21.784 20.308V16.5a3.546 3.546 0 00-4.552-3.398l-4.915 1.442a3.543 3.543 0 00-2.548 3.398v14.175a3.543 3.543 0 002.548 3.398l4.915 1.443a3.551 3.551 0 004.552-3.398v-3.312c0-1.062.563-2.045 1.48-2.584l4.977-2.92a5.72 5.72 0 002.827-4.932V16.5a.818.818 0 011.05-.784l4.915 1.442a.818.818 0 01.588.784v14.175c0 .363-.24.682-.588.785l-4.915 1.442a.82.82 0 01-1.05-.784V28.66c0-.752-.71-1.211-1.366-.817-.654.394-1.365 1.155-1.365 1.907v3.809a3.551 3.551 0 004.551 3.398l4.916-1.443a3.543 3.543 0 002.548-3.398V17.942a3.543 3.543 0 00-2.548-3.398l-4.916-1.442a3.546 3.546 0 00-4.551 3.398v3.312a2.996 2.996 0 01-1.48 2.583l-4.977 2.92a5.72 5.72 0 00-2.827 4.933v3.312a.82.82 0 01-1.05.784l-4.915-1.442a.818.818 0 01-.588-.785V17.942c0-.363.24-.682.588-.784l4.915-1.442a.82.82 0 011.05.784v4.898c0 .752.668 1.1 1.365.817.698-.282 1.366-1.154 1.366-1.907z"
          fill="#FFF"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}
