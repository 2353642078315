import { HevyMenu, HevyMenuItem } from 'components/HevyMenu';
import React from 'react';
import { Icon } from 'styleguide/Icon';
import { zIndex } from 'styleguide/Modal';

interface CommentOptionsMenuProps {
  onDeleteClick: () => void;
}

export const CommentOptionsMenu = ({ onDeleteClick }: CommentOptionsMenuProps) => (
  <HevyMenu
    direction="left"
    menuStyle={{ zIndex: zIndex.modalOverlay }}
    menuButton={
      <div style={{ display: 'flex', cursor: 'pointer' }}>
        <Icon type="horizontalDotsAccent" />
      </div>
    }
  >
    <HevyMenuItem
      onClick={onDeleteClick}
      iconType="xSmallGrey"
      title="Delete Comment"
    ></HevyMenuItem>
  </HevyMenu>
);
