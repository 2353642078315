import { SearchUser } from 'hevy-shared';
import { makeAutoObservable } from 'mobx';
import toast from 'react-hot-toast';
import { stores } from 'state/stores';
import API from 'utils/API';
import { sendEvent } from 'utils/events';
import { UserListModalViewModalInterface } from './types';

export interface FollowerListViewModelProps {
  username: string;
}

export class FollowerListViewModel implements UserListModalViewModalInterface {
  title = 'Followers';
  username: string;
  searchText: string = '';
  followers: SearchUser[] = [];
  isLoading: boolean = false;
  isFetchingMore: boolean = false;

  constructor(props: FollowerListViewModelProps) {
    makeAutoObservable(this);
    this.username = props.username;
    this.fetchFollowers();
  }

  get users() {
    return this.followers;
  }

  get noResultsText() {
    if (this.searchText.length > 0) {
      return `No results for "${this.searchText}"`;
    } else {
      return `No one is following ${this.username} yet.`;
    }
  }

  onSearchFollowers = async () => {
    this.isLoading = true;
    await this.fetchFollowersWithSearch(this.searchText);
    this.isLoading = false;
  };

  handleScroll = async (e: React.UIEvent<HTMLElement>) => {
    const scrollBottom = e.currentTarget.scrollTop + e.currentTarget.offsetHeight;

    const scrollHeight = e.currentTarget.scrollHeight;
    const fetchMoreThreshold = scrollHeight - 50;

    if (scrollBottom > fetchMoreThreshold && !this.isFetchingMore) {
      this.isFetchingMore = true;
      await this.fetchMoreFollowers();
      this.isFetchingMore = false;
    }
  };

  onSearchTextChange = (value: string) => {
    this.searchText = value;
    if (this.searchText.length > 2) {
      this.onSearchFollowers();
    } else if (this.searchText.length === 0) {
      this.fetchFollowers();
    }
  };

  onFollowUserClick = async (user: SearchUser) => {
    sendEvent('followerList_userFollowPress', { userId: user.id });
    try {
      this.followers.find(u => u.id === user.id)!.following_status = 'requested';
      await stores.followingStatus.follow(user.username);
    } catch (e) {
      sendEvent('followerList_userFollowPress_Error', { userId: user.id });
      toast.error('Failed to follow user');
    } finally {
      this.followers.find(u => u.id === user.id)!.following_status = stores.followingStatus.status(
        user.username,
      );
    }
  };

  onUnfollowUserClick = async (user: SearchUser) => {
    sendEvent('followerList_userUnfollowPress', { userId: user.id });
    try {
      this.followers.find(u => u.id === user.id)!.following_status = 'not-following';
      await stores.followingStatus.unfollow(user.username);
    } catch {
      sendEvent('followerList_userUnfollowPress_Error', { userId: user.id });
      toast.error('Failed to unfollow user');
    } finally {
      this.followers.find(u => u.id === user.id)!.following_status = stores.followingStatus.status(
        user.username,
      );
    }
  };

  fetchFollowers = async () => {
    this.isLoading = true;
    let response;
    try {
      response = await API.getFollowersPaged(this.username, 0);
    } catch {
      return;
    }

    this.followers = response.data;
    this.isLoading = false;
  };

  fetchMoreFollowers = async () => {
    this.isLoading = true;
    let response;
    try {
      response = await API.getFollowersPaged(this.username, this.followers.length);
    } catch {
      return;
    }

    this.followers.push(...response.data);
    this.isLoading = false;
  };

  fetchFollowersWithSearch = async (search: string) => {
    this.isLoading = true;
    let response;
    try {
      response = await API.searchFollowers(this.username, search);
    } catch {
      return;
    }

    this.followers = response.data;
    this.isLoading = false;
  };
}
