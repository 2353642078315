import { Weekday } from 'hevy-shared';

export interface FormatAtText {
  format: 'none' | '@';
  text: string;
}

const removeFirstOccurrence = (str: string, searchstr: string): string => {
  const index = str.indexOf(searchstr);
  if (index === -1) {
    return str;
  }
  return str.slice(0, index) + str.slice(index + searchstr.length);
};

export const splitAtUsernames = (text: string): FormatAtText[] => {
  /**
   * Matches all valid usernames preceded by an `@` character, which is not in
   * the middle of a word itself
   */
  const mentions = text.match(/\B@[a-z0-9_-]+/gi);
  if (!mentions) {
    return [{ format: 'none', text }];
  }

  let format: FormatAtText[] = [];
  let unprocessedText = text;
  for (const mention of mentions) {
    const textBeforeMention = unprocessedText.split(mention, 1)[0];
    if (textBeforeMention) {
      format = [...format, { format: 'none', text: textBeforeMention }];
      unprocessedText = removeFirstOccurrence(unprocessedText, textBeforeMention);
    }

    format = [...format, { format: '@', text: mention }];
    unprocessedText = removeFirstOccurrence(unprocessedText, mention);
  }

  if (unprocessedText) {
    format = [...format, { format: 'none', text: unprocessedText }];
  }

  return format;
};

export const weekdayNumberMap: { [key in Weekday]: number } = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
};
