import { SearchUser } from 'hevy-shared';
import { debounce } from 'lodash';
import { makeAutoObservable } from 'mobx';
import API from 'utils/API';

export class NavBarUserSearchViewModel {
  searchString = '';
  results: SearchUser[] = [];
  debouncedSearch: () => void;
  isLoadingResults = false;

  constructor() {
    makeAutoObservable(this);
    this.debouncedSearch = debounce(this.searchForUsers, 500);
  }

  get showMenu() {
    return this.searchString.length > 2;
  }

  onSearchStringChanged = (newValue: string) => {
    this.searchString = newValue.toLowerCase();
    if (this.searchString.length < 3) {
      return;
    }
    this.isLoadingResults = true;
    this.debouncedSearch();
  };

  searchForUsers = async () => {
    try {
      const result = await API.userSearch(this.searchString.toLowerCase());
      this.results = result.data;
    } catch (error) {}
    this.isLoadingResults = false;
  };
}
