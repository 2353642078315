import '../styles.css';
import React from 'react';
import { Toaster } from 'react-hot-toast';
import { useEffect } from 'react';
import Modal from 'react-modal';
import { bootstrap } from 'utils/bootstrap';
import { HevyAppNavigationWrapper } from 'components/NavBar/HevyAppNavigationWrapper';
import { ModalManager } from 'components/Modals/ModalManager';
import { FeedbackTag } from 'components/FeedbackTag';
import { useRouter } from 'next/router';
import { gaRecordPageview, gaInit } from '../utils/ga';
import { themeState } from 'styleguide/theme';
import { ThemeProvider } from 'styled-components';
import { Observer } from 'mobx-react-lite';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { stores } from 'state/stores';

Modal.setAppElement('#__next');
dayjs.extend(customParseFormat);

interface Props {
  Component: any;
  pageProps: any;
}

let typedWord = '';
const handleGlobalKeyPress = (event: KeyboardEvent) => {
  const keyPressed = event.key;

  // Check if the pressed key is a single letter
  if (/^[A-Za-z]$/.test(keyPressed)) {
    typedWord += keyPressed.toLowerCase();

    if (typedWord === 'hevybros') {
      stores.betaFeatures.areBetaFeaturesEnabled = true;
    }
  } else {
    typedWord = '';
  }
};

function MyApp(props: Props) {
  const router = useRouter();

  useEffect(() => {
    bootstrap();
    window.onkeydown = handleGlobalKeyPress;
  }, []);

  useEffect(() => {
    gaInit();

    //When the component is mounted, subscribe to router changes
    //and log those page views
    router.events.on('routeChangeComplete', gaRecordPageview);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off('routeChangeComplete', gaRecordPageview);
    };
  }, [router.events]);

  return (
    <Observer>
      {() => (
        <ThemeProvider theme={themeState.current}>
          <div
            style={{
              backgroundColor: themeState.current.background,
              colorScheme: themeState.current.name,
            }}
          >
            <Toaster position="top-center" reverseOrder={false} />
            <FeedbackTag />
            <ModalManager />
            <HevyAppNavigationWrapper isAuthenticated={!!props?.pageProps?.isAuthenticated}>
              <props.Component {...props.pageProps} />
            </HevyAppNavigationWrapper>
          </div>
        </ThemeProvider>
      )}
    </Observer>
  );
}

export default MyApp;
