import React from 'react';
import styled from 'styled-components';
import { TextSMMedium, TextXSRegular } from 'styleguide/Texts';
import { StatProps } from '../types';
import { View } from 'styleguide/View';
import { Icon } from 'styleguide/Icon';
import { Spacing } from 'styleguide/spacing';

const StatContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${2 * Spacing.md}px;
`;

const Stat = ({ title, value, isPr }: StatProps) => {
  return (
    <StatContainer>
      <TextXSRegular style={{ marginBottom: Spacing.xs }}>{title}</TextXSRegular>
      {isPr ? (
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Icon
            type={'pr'}
            style={{ height: Spacing.md, width: Spacing.md, paddingRight: Spacing.xs }}
          ></Icon>
          <TextSMMedium>{value}</TextSMMedium>
        </View>
      ) : (
        <TextSMMedium>{value}</TextSMMedium>
      )}
    </StatContainer>
  );
};

const Container = styled.div`
  display: flex;
`;

interface Props {
  stats: StatProps[];
}

export const Stats = ({ stats }: Props) => {
  return (
    <Container>
      {stats.map(s => (
        <Stat key={s.title} {...s} />
      ))}
    </Container>
  );
};
