import React, { CSSProperties, useState } from 'react';
import styled, { ThemeProps } from 'styled-components';
import { colors } from './colors';
import fonts from './fonts';
import { TextSMMedium } from './Texts';
import { Theme } from './types';
import { themeState } from './theme';
import { ThemedIcon, ThemedIconType } from './ThemedIcon';
import { ClipLoader } from 'react-spinners';
import { CornerRadius, Spacing } from './spacing';
import Link from 'next/link';
import { View } from './View';

//Buttons are for clicking and changing state
//Links are for navigating inside the app with routes

export const TertiaryButtonLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  color: ${colors.primary500};
  text-decoration: none;
  font-family: ${fonts.regular.family};
  font-weight: ${fonts.regular.weight};
  font-size: 14px;
  cursor: pointer;
`;

export const TertiaryButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  color: ${colors.primary500};
  text-decoration: none;
  font-family: ${fonts.regular.family};
  font-weight: ${fonts.regular.weight};
  font-size: 14px;
  cursor: pointer;
  outline: none;
`;

interface PrimaryButtonProps {
  title: string;
  type?: 'submit';
  enabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
  style?: CSSProperties;
}

export const PrimaryButtonLink = (props: PrimaryButtonProps & { to: string }) => {
  return (
    <Link href={props.to} style={{ textDecoration: 'none' }}>
      <View>
        <PrimaryButton {...props} />
      </View>
    </Link>
  );
};

const StyledPrimaryButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${CornerRadius.sm}px;
  border: none;
  height: 35px;
  color: ${colors.white};
  font-family: ${fonts.regular.family};
  font-weight: 400;
  font-size: 14px;
  outline: none;
  background-color: ${(props: ThemeProps<Theme> & { enabled: boolean }) => {
    if (props.enabled) {
      return colors.primary500;
    }

    return props.theme.name === 'dark' ? colors.darkModePrimary100 : colors.primary100;
  }};
`;

export const PrimaryButton = ({
  title,
  type,
  enabled = true,
  loading = false,
  onClick,
  style,
}: PrimaryButtonProps) => {
  const [isHovering, setIsHovering] = useState(false);
  const [isClicking, setIsClicking] = useState(false);

  return (
    <StyledPrimaryButton
      type={type}
      enabled={enabled}
      style={{
        ...(enabled
          ? {
              backgroundColor: colors.primary500,
              cursor: 'pointer',
              pointerEvents: 'auto',
            }
          : {
              cursor: 'default',
              pointerEvents: 'none',
            }),
        ...(loading ? { pointerEvents: 'none' } : {}),
        ...(isHovering && enabled && { backgroundColor: colors.hevyBlueOff }),
        ...(isClicking && enabled && { backgroundColor: colors.hevyBlueOffOff }),
        ...style,
      }}
      onClick={onClick}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onMouseDown={() => setIsClicking(true)}
      onMouseUp={() => setIsClicking(false)}
      onMouseOut={() => setIsClicking(false)}
    >
      {loading ? (
        <ClipLoader size={20} color={colors.white} loading={true} />
      ) : (
        <TextSMMedium style={{ color: colors.white }}>{title}</TextSMMedium>
      )}
    </StyledPrimaryButton>
  );
};

const StyledIconButtonP1 = styled(TextSMMedium)`
  color: ${(props: ThemeProps<Theme>) =>
    props.theme.name === 'light' ? colors.neutral900 : colors.white};
`;

interface SecondaryButtonProps {
  title: string;
  enabled?: boolean;
  loading?: boolean;
  iconType?: ThemedIconType;
  iconTint?: string;
  iconStyle?: CSSProperties;
  onClick?: () => void;
  style?: CSSProperties;
}

const StyledSecondaryButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-style: solid;
  border-width: 1px;
  border-color: ${(props: ThemeProps<Theme>) => props.theme.border};
  background-color: ${(props: ThemeProps<Theme>) =>
    props.theme.name === 'light' ? colors.white : colors.darkmodeNeutral150};
  border-radius: ${CornerRadius.sm}px;
  height: 35px;
  font-family: ${fonts.regular.family};
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  pointer-events: auto;
  outline: none;
`;

export const SecondaryButton = ({
  title,
  loading = false,
  onClick,
  style,
  iconType,
  iconTint,
  iconStyle,
  enabled = true,
}: SecondaryButtonProps) => {
  const [isHovering, setIsHovering] = useState(false);
  const [isClicking, setIsClicking] = useState(false);

  return (
    <StyledSecondaryButton
      disabled={!enabled}
      style={{
        ...(isHovering &&
          enabled && {
            backgroundColor:
              themeState.current.name === 'light' ? colors.neutral50 : colors.darkmodeNeutral150,
          }),
        ...(isClicking && {
          backgroundColor: themeState.current.neutral100,
        }),
        ...(loading ? { pointerEvents: 'none' } : {}),
        ...style,
        cursor: enabled ? 'pointer' : 'default',
      }}
      onClick={onClick}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onMouseDown={() => setIsClicking(true)}
      onMouseUp={() => setIsClicking(false)}
      onMouseOut={() => setIsClicking(false)}
    >
      {iconType && (
        <ThemedIcon
          type={iconType}
          style={{ marginLeft: Spacing.sm, marginRight: Spacing.sm, ...iconStyle }}
          dark={iconTint || colors.white}
          light={iconTint || colors.black}
        />
      )}
      <StyledIconButtonP1>{loading ? 'Loading...' : title}</StyledIconButtonP1>
    </StyledSecondaryButton>
  );
};

export const SecondaryButtonLink = (props: SecondaryButtonProps & { to: string }) => {
  return (
    <Link href={props.to} style={{ textDecoration: 'none' }}>
      <SecondaryButton {...props} />
    </Link>
  );
};
