import { makeAutoObservable } from 'mobx';
import { SearchUser } from 'hevy-shared';
import API from 'utils/API';

export class RecommendedUsers {
  users: SearchUser[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  fetch = async () => {
    const response = await API.getRecommendedUsers();

    if (!response.data || !response.data.length) {
      this.users = [];
      return;
    }

    this.users = response.data;
  };
}
