/**
 * Helper function for using promises and ignoring if they fail.
 */

export const fireAndForget = async (promises: Array<Promise<any>>) => {
  try {
    await Promise.all(promises);
  } catch {
    // Do nothing;
  }
};
