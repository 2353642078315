import { stores } from 'state/stores';
import { sendEvent } from './events';

export const clearAllUserData = () => {
  stores.lifecycle.isBootstrapped = false;
  stores.account.clearData();
  stores.routines.clearData();
  stores.subscription.clearData();
  stores.routineFolders.clearData();
  stores.userPreferences.clearData();
  stores.exerciseTemplates.clearData();
  stores.exerciseTemplateUnits.clearData();
  stores.betaFeatures.clearData();
  stores.auth.logout();
};

export const logout = () => {
  sendEvent('logout');

  clearAllUserData();
  window.location.pathname = '/login';
};
