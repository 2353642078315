import { makeAutoObservable } from 'mobx';
import { isPublicWorkout, Workout } from 'hevy-shared';
import API from 'utils/API';

export class Workouts {
  workoutCount: number = 0;
  workouts: { [workoutId: string]: Workout } = {};
  private feedWorkoutIds: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  get feedWorkouts(): Workout[] {
    return this.feedWorkoutIds.map(workoutId => this.workouts[workoutId]).filter(w => !!w);
  }

  /**
   * Returns the workouts for a given user, sorted by start time descending
   */
  getUserWorkouts = (username: string): Workout[] => {
    return Object.values(this.workouts)
      .filter(w => w.username === username)
      .sort((w1, w2) => w2.start_time - w1.start_time);
  };

  /**
   * Feed
   */

  refreshFeed = async () => {
    const response = await API.getFeedPaged();

    const workouts = response.data.workouts;

    if (!workouts || !workouts.length) {
      this.feedWorkoutIds = [];
      return;
    }

    for (const workout of workouts) {
      this.workouts[workout.id] = workout;
    }

    this.feedWorkoutIds = workouts.map(w => w.id);
  };

  feedFetchMore = async () => {
    const lastFeedWorkoutId = this.feedWorkoutIds[this.feedWorkoutIds.length - 1];
    const lastFeedWorkoutIndex = this.workouts[lastFeedWorkoutId]?.index;

    if (lastFeedWorkoutIndex === undefined) return;

    const response = await API.getFeedPaged(lastFeedWorkoutIndex);

    const workouts = response.data.workouts;

    for (const workout of workouts) {
      this.workouts[workout.id] = workout;
    }

    this.feedWorkoutIds = [...this.feedWorkoutIds, ...workouts.map(w => w.id)];
  };

  /**
   * User workouts
   */

  fetchWorkoutCount = async () => {
    const result = await API.getWorkoutCount();
    this.workoutCount = result.data.workout_count || 0;
  };

  refreshUserWorkouts = async (username: string) => {
    const response = await API.getUserWorkoutsPaged({ username, limit: 2, offset: 0 });

    const workouts = response.data.workouts;
    for (const workout of workouts) {
      this.workouts[workout.id] = workout;
    }
  };

  fetchMoreUserWorkouts = async (username: string, limit?: number) => {
    const userWorkouts = this.getUserWorkouts(username);

    if (userWorkouts.length === 0) {
      return this.refreshUserWorkouts(username);
    }

    const response = await API.getUserWorkoutsPaged({
      username,
      limit: limit || 3,
      offset: userWorkouts.length,
    });

    const workouts = response.data.workouts;
    for (const workout of workouts) {
      this.workouts[workout.id] = workout;
    }
  };

  fetchWorkout = async (workoutId: string) => {
    const response = await API.getWorkout(workoutId);
    if (isPublicWorkout(response.data)) return;
    this.workouts[response.data.id] = response.data;
  };

  likeWorkout = async (workoutId: string) => {
    const workout = this.workouts[workoutId];
    if (!workout) {
      return;
    }

    const newLikeState = !workout.is_liked_by_user;

    workout.is_liked_by_user = newLikeState;

    try {
      if (newLikeState === true) {
        workout.like_count += 1;
        await API.likeWorkout(workoutId);
      } else {
        workout.like_count -= 1;
        await API.unlikeWorkout(workoutId);
      }
    } catch {
      workout.is_liked_by_user = !newLikeState;
    }
  };
}
