import { makeAutoObservable } from 'mobx';
import { RoutineFolder as TRoutineFolder, RoutineFolderLocationUpdate } from 'hevy-shared';
import API from 'utils/API';
import { moveItemInArray } from 'utils/misc';

const ROUTINE_FOLDERS_LOCAL_STORAGE_KEY = 'ROUTINE_FOLDERS_LOCAL_STORAGE_KEY';

export class RoutineFolders {
  folders: TRoutineFolder[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  hydrate = () => {
    const foldersJSON = window.localStorage.getItem(ROUTINE_FOLDERS_LOCAL_STORAGE_KEY);
    if (foldersJSON) {
      this.folders = JSON.parse(foldersJSON);

      window.localStorage.setItem(ROUTINE_FOLDERS_LOCAL_STORAGE_KEY, JSON.stringify(this.folders));
    }
  };

  clearData = () => {
    this.folders = [];
    window.localStorage.removeItem(ROUTINE_FOLDERS_LOCAL_STORAGE_KEY);
  };

  fetch = async () => {
    const result = await API.getRoutineFolders();
    this.folders = result.data;
    window.localStorage.setItem(ROUTINE_FOLDERS_LOCAL_STORAGE_KEY, JSON.stringify(result.data));
  };

  updateFolderLocation = async (s: RoutineFolderLocationUpdate, d: RoutineFolderLocationUpdate) => {
    const orderedFolders = this.folders.slice().sort((f1, f2) => f1.index - f2.index);
    const newSortedFolders = moveItemInArray(orderedFolders, s.index, d.index);
    for (let i = 0; i < newSortedFolders.length; i++) {
      newSortedFolders[i].index = i;
    }

    this.folders = newSortedFolders;

    const newLocations: RoutineFolderLocationUpdate[] = this.folders.map(f => ({
      folderId: f.id,
      index: f.index,
    }));

    await API.updateRoutineFolderOrder(newLocations);
  };

  getFolderById = (folderId: number) => {
    return this.folders.find(f => f.id === folderId);
  };
}
