import { Link } from 'components/Link';
import { RoutineMetadata } from 'hevy-shared';
import React from 'react';
import styled from 'styled-components';
import { Card } from 'styleguide/Card';
import { DividerLine } from 'styleguide/DividerLine';
import { Icon } from 'styleguide/Icon';
import { Modal } from 'styleguide/Modal';
import { FlexRow } from 'styleguide/Row';
import { TextLG, TextSMMedium } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { Spacing } from 'styleguide/spacing';

const Container = styled(View)`
  width: 500px;
`;

const UpperContainer = styled(FlexRow)`
  justify-content: space-between;
  padding: ${Spacing.lg}px;
`;

const LowerContainer = styled(View)`
  overflow-y: auto;
  padding: ${Spacing.lg}px;
`;

export interface RoutineListModalProps {
  routines: RoutineMetadata[];
  isOpen: boolean;
  onClose: () => void;
}

export const RoutineListModal = ({ routines, isOpen, onClose }: RoutineListModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} contentStyle={{ padding: 0 }}>
      <Container>
        <UpperContainer>
          <TextLG>Routines</TextLG>
          <View style={{ cursor: 'pointer' }} onClick={onClose}>
            <Icon type="xSmallGrey"></Icon>
          </View>
        </UpperContainer>
        <DividerLine></DividerLine>
        <LowerContainer>
          <View style={{ gap: Spacing.sm }}>
            {routines.map(r => (
              <Link key={r.short_id} href={`/routine/${r.short_id}`} onClick={onClose}>
                <Card style={{ padding: Spacing.md }}>
                  <TextSMMedium>{r.title}</TextSMMedium>
                </Card>
              </Link>
            ))}
          </View>
        </LowerContainer>
      </Container>
    </Modal>
  );
};
