import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { PrimaryButton, SecondaryButton } from 'styleguide/Buttons';
import { Modal } from 'styleguide/Modal';
import { H1, TextMD } from 'styleguide/Texts';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  border-radius: 10px;
`;

const UpperContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LowerContainer = styled.div`
  display: flex;
  margin-top: 20px;
`;

export interface ConfirmationModalProps {
  title: string;
  subtitle: string;
  submitButtonTitle: string;
  onConfirm: () => void;
  isOpen: boolean;
  onClose: () => void;
}

export const ConfirmationModal = ({
  title,
  subtitle,
  submitButtonTitle,
  onConfirm,
  isOpen,
  onClose,
}: ConfirmationModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Container>
        <UpperContainer>
          <H1 style={{ marginBottom: 20 }}>{title}</H1>
          <TextMD>{subtitle}</TextMD>
        </UpperContainer>
        <LowerContainer>
          <SecondaryButton style={styles.button} title="Cancel" onClick={onClose} />
          <div style={{ width: 20 }} />
          <PrimaryButton style={styles.button} title={submitButtonTitle} onClick={onConfirm} />
        </LowerContainer>
      </Container>
    </Modal>
  );
};

const styles: { [key: string]: CSSProperties } = {
  button: {
    display: 'flex',
    flex: 1,
  },
};
