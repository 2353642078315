export const colors = {
  // Old Brand Colors
  /** @deprecated */
  hevyBlueOff: '#056DD7',
  /** @deprecated */
  hevyBlueOffOff: '#0263C5',
  /** @deprecated */
  darkModePrimary100: '#203B56',
  /** @deprecated */
  darkModeGrey: '#232425',
  /** @deprecated */
  darkGrey: '#4A4A4A',

  // New Brand Colors
  primary25: '#F3F9FE',
  primary50: '#E8F2FD',
  primary100: ' #D1E6FB',
  primary200: '#A2CCF7',
  primary300: '#74B3F2',
  primary400: '#4599EE',
  primary500: '#1780EA',
  primary600: '#1266BB',
  neutral50: '#F9FAFB', // background of the app on light mode
  neutral100: '#F3F4F6',
  neutral150: '#ECEDF0',
  neutral200: '#E5E7EB',
  neutral300: '#D2D6DB',
  neutral400: '#9DA4AE',
  neutral500: '#6C737F',
  neutral700: '#384250',
  neutral900: '#111927',
  error500: '#F04438',
  warningYellow: '#FEF0C7',

  // New Darkmode Colors
  darkmodeNeutral00: '#191A1C', // background of the app
  darkmodeNeutral50: '#1F2022', // background of a component
  darkmodeNeutral100: '#292B2F',
  darkmodeNeutral150: '#2D2F32',
  darkmodeNeutral200: '#42454A',
  darkmodeNeutral300: '#646A74',
  darkmodeNeutral400: '#6D737C',
  darkmodeNeutral500: '#898F99',
  darkmodeNeutral600: '#A7ABB2',
  darkmodeNeutral700: '#C4C7CC',
  darkmodeNeutral800: '#E2E3E5',
  darkmodeNeutral900: '#EBEBED',

  // Old Darkmode
  /** @deprecated */
  backgroundDark: '#181818',

  // General
  transparent: 'transparent',
  black: '#000000',
  white: '#FFFFFF', // background of a component on light mode
  red: '#FF0000',
  supersets: [
    { bold: '#A720F4', light: '#FAF0FF' },
    { bold: '#43C616', light: '#F2FAF2' },
    { bold: '#DD1818', light: '#FFEEEE' },
    { bold: '#E2B335', light: '#FFFBE0' },
    { bold: '#E26AE9', light: '#FEF0FF' },
    { bold: '#4A4A4A', light: '#F8F8F8' },
    { bold: '#FF8787', light: '#FFEEEE' },
    { bold: '#203CF4', light: '#F3F5FF' },
    { bold: '#28C2D3', light: '#E8F5F7' },
    { bold: '#A2B139', light: '#F3F6DD' },
  ],
};
