import { observer } from 'mobx-react-lite';
import React, { CSSProperties } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { PrimaryButton, SecondaryButton } from 'styleguide/Buttons';
import { colors } from 'styleguide/colors';
import { Modal } from 'styleguide/Modal';
import { H1, TextMD } from 'styleguide/Texts';
import { themeState } from 'styleguide/theme';
import { View } from 'styleguide/View';

import ExclamationCircleImage from './assets/exclamation-circle.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  border-radius: 10px;
`;

const UpperContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LowerContainer = styled.div`
  display: flex;
  margin-top: 20px;
`;

export interface DestructiveConfirmationModalProps {
  title: string;
  subtitle: string;
  submitButtonTitle: string;
  onConfirm: () => void;
  isOpen: boolean;
  onClose: () => void;
}

export const DestructiveConfirmationModal = observer(
  ({
    title,
    subtitle,
    submitButtonTitle,
    onConfirm,
    isOpen,
    onClose,
  }: DestructiveConfirmationModalProps) => {
    return (
      <ThemeProvider theme={themeState.current}>
        <Modal isOpen={isOpen} onClose={onClose}>
          <Container>
            <UpperContainer>
              <View style={{ padding: 20 }}>
                <ExclamationCircleImage />
              </View>
              <H1 style={{ marginBottom: 20 }}>{title}</H1>
              <TextMD>{subtitle}</TextMD>
            </UpperContainer>
            <LowerContainer>
              <SecondaryButton style={styles.button} title="Cancel" onClick={onClose} />
              <div style={{ width: 20 }} />
              <PrimaryButton
                style={{ ...styles.button, backgroundColor: colors.red }}
                title={submitButtonTitle}
                onClick={onConfirm}
              />
            </LowerContainer>
          </Container>
        </Modal>
      </ThemeProvider>
    );
  },
);

const styles: { [key: string]: CSSProperties } = {
  button: {
    display: 'flex',
    flex: 1,
  },
};
