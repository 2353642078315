import { env } from 'env';
import { log } from './log';
import { isProduction } from './misc';
import EventEmitter from 'events';

const PADDLE_SANDBOX_VENDOR_ID = 14492;
const PADDLE_PRODUCTION_VENDOR_ID = 177530;

export const Paddle: any = typeof window !== 'undefined' ? (window as any).Paddle : undefined;

// Amount of time we wait for Paddle to call our backend with updated subscription/purchase data for this user
const PADDLE_WEBHOOK_DELAY_MS = 1000 * 10;

// How long to show the Paddle success modal before we automatically close it
const PADDLE_SUCCESS_MODAL_DELAY = 1000 * 5;

// See https://developer.paddle.com/paddlejs/events/overview for possible event names emitted
export const PaddleEventEmitter = new EventEmitter();
export const initPaddle = () => {
  const vendorId = isProduction() ? PADDLE_PRODUCTION_VENDOR_ID : PADDLE_SANDBOX_VENDOR_ID;
  log(`💰Initializing Paddle with ${vendorId}`);
  if (env.env !== 'production') {
    Paddle.Environment.set('sandbox');
  }

  Paddle.Setup({
    seller: vendorId,
    eventCallback: (eventData: any) => {
      PaddleEventEmitter.emit(eventData.name);
    },
  });
};

// Wrap and promisify the Paddle checkout function because they have no event callbacks built in
export const openPaddleCheckout = async (
  paddlePriceId: string,
  userEmail: string,
  userId: string,
  discountCode?: string,
): Promise<{ didFinalizePurchase: boolean }> => {
  return new Promise<{ didFinalizePurchase: boolean }>(resolve => {
    const onClosedEvent = () => {
      resolve({ didFinalizePurchase: false });
      PaddleEventEmitter.removeListener('checkout.closed', onClosedEvent);
      PaddleEventEmitter.removeListener('checkout.completed', onCompletedEvent);
    };
    PaddleEventEmitter.on('checkout.closed', onClosedEvent);

    const onCompletedEvent = () => {
      // Wait for Paddle to call our backend webhook before resolving
      setTimeout(() => {
        resolve({ didFinalizePurchase: true });
      }, PADDLE_WEBHOOK_DELAY_MS);

      PaddleEventEmitter.removeListener('checkout.closed', onClosedEvent);
      PaddleEventEmitter.removeListener('checkout.completed', onCompletedEvent);

      setTimeout(() => {
        Paddle.Checkout.close();
      }, PADDLE_SUCCESS_MODAL_DELAY);
    };
    PaddleEventEmitter.on('checkout.completed', onCompletedEvent);

    Paddle.Checkout.open({
      items: [{ priceId: paddlePriceId, quantity: 1 }],
      email: userEmail,
      allowLogout: false,
      customData: JSON.stringify({
        user_id: userId,
        user_email: userEmail,
      }),
      customer: {
        email: userEmail,
      },
      discountCode,
    });
  });
};
